import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/SuperAdmin/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from "../../theme";

const CreateStage = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/stage/`, values);

      toast.success('Stage created successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      resetForm();
      navigate('/superadmin/stages');
    } catch (error) {
      if (error.response) {
        setErrorMessage(`Server responded with a ${error.response.status} error`);
      } else if (error.request) {
        setErrorMessage('No response received from the server');
      } else {
        setErrorMessage('Unexpected error occurred');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE STAGE" subtitle="Create a New Stage" />

      <Formik
        initialValues={{
          StageName: '',
          Content: '',
          Stakeholders: '', // Assuming Stakeholders is part of the form
        }}
        validationSchema={stageSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {errorMessage && (
              <Typography color="secondary" variant="body1" mt={2}>
                {errorMessage}
              </Typography>
            )}

            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              {/* StageName Input */}
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                type="text"
                label="Stage Name"
                name="StageName"
                sx={{ gridColumn: "span 4" }}
              />

              {/* Content Input */}
              <Field
                as={TextField}
                fullWidth
                variant="filled"
                type="text"
                label="Content"
                name="Content"
                sx={{ gridColumn: "span 4" }}
              />

           
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" sx={{ backgroundColor: colors.primary[500] }}  variant="contained" disabled={isSubmitting}>
                Create New Stage
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const stageSchema = yup.object().shape({
  StageName: yup.string().required('Stage Name is required'),
  Content: yup.string().required('Content is required'),
 
});

export default CreateStage;
