import React, {useState, useContext} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './AddDocument.css';
import { useAuth } from '../../../context/auth';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminContext } from '../../../context/admincontext';
import { WorkgroupContext } from '../../../context/workgroupContext';

const AddDocument = () => {
  JSON.parse(localStorage.getItem("USER_SGP"));
  const { user } = useAuth();
  const userId = user.user._id;
  const userRole = user.user.UserRole;
  const { Wname } = useParams();
  const [abbreviation] = React.useState(Wname.match(/\(([^)]+)\)/)?.[1] || '');
  const { id: groupId } = useParams();
  const navigate = useNavigate();

  const [errorUploading, setErrorUploading] = useState();
  const [loading, setLoading] = useState(false);
  const { disallowApproval } = useContext(AdminContext);
  const { addDocument } = useContext(WorkgroupContext);

  const handleCancelClick = () => {
    
    if (userRole === 'admin'){
     
      navigate(`/admin?id=${groupId}&name=${Wname}`);
    }

    if (userRole === 'member'){
      navigate(`/member?id=${groupId}&name=${Wname}`);
    }
    
  };


  const validationSchema = Yup.object({
    file: Yup.mixed()
      .required('File is required')
      .test('fileType', 'Only PDF files are allowed', (value) => value && value.type === 'application/pdf'),
    name: Yup.string().required('Document Name is required'),
    Email: Yup.string().email('Invalid email address'),
  });

  const formik = useFormik({
    initialValues: {
      file: null,
      name: '',
  
      
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const formattedName = values.name.replace(/\s+/g, '_');
      formData.append('file', values.file);
      formData.append('name', formattedName);
      formData.append('userID', userId);
      formData.append('context', abbreviation);
  

      try {
        setLoading(true);
        const uploadDocument = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/document/${groupId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });


        if (disallowApproval === true || userRole === 'admin'){
          const documentId = uploadDocument.data.savedDocument._id;
          try {

            try{
              const updatedDocument = await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/document/approveDocument/${documentId}`);
              
              const documentContext = updatedDocument.data.context;
              const documentFilename = updatedDocument.data.image;

          
              try {
                await axios.post('https://staging-bayesnet.bayesconsultants.com/api/acmi/upload', {
                  context: documentContext,
                  filename: documentFilename,
                });

                console.log('')
              }catch(error){
                console.log(error.response.data)
              }
            }catch (error){
              console.log(error.response.data)
            }

           

          }catch (error){
            console.log (error.response.data)
          }
        }


        toast.success('Document Added Successfully', { containerId: 'container3' });
        addDocument();

        if (userRole === 'member'){
          navigate(`/member?id=${groupId}&name=${Wname}`);
        }

        if (userRole === 'admin'){
          navigate(`/admin?id=${groupId}&name=${Wname}`);
        }
      
       

      } catch (error) {
        console.error('Error submitting document:', error);
        // Handle error as needed

        setErrorUploading('Failed to Upload Document. Please try again');

      }finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="AddDocument">
     
      <div className="add-document-container">

        <div>
         <img src="/acmi-logo-color.png" alt="Logo" className="logo" />
        </div>
  
        <h3 style ={{marginBlock: '5px'}}>Add Document</h3>
        <p style ={{marginBlock: '0px', marginBottom:'12px', color: '#333333', fontSize:'12px', lineHeight:'2'}}>Add a new document to the workgroup {Wname}</p>

        {errorUploading && (
          <p className="error-name">{errorUploading}</p>
        )}

        <form onSubmit={formik.handleSubmit} className="form-container">
         
          <div className="form-group">
            <input
              id="nameInput"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autocomplete="off"
              type="text"
              className={`did-floating-input ${formik.values.name ? 'focused' : ''} ${
                  formik.touched.name && formik.errors.name ? 'input-error' : ''
                }`}
              name="name"
            />

            <label className="did-floating-label">File Name</label>
            {formik.touched.name && formik.errors.name && (
              <div className="formik-error" style={{ color: '#d32f2f' }}>
                {formik.errors.name}
              </div>
            )}
          </div>


          <div className="form-group">
          
            <input
              id="contextInput"
              value={abbreviation}
              readOnly
              type="text"
              name="context"
              className="did-floating-input"
            />

          </div>

          

          <div className="file-upload">
          
              
              <input
                id="fileInput"
                onChange={(e) => formik.setFieldValue('file', e.currentTarget.files[0])}
                type="file"
                name="file"
                accept=".pdf"
              />
         
            {formik.touched.file && formik.errors.file && (
              <div className="formik-error" style={{ color: '#d32f2f' }}>
                {formik.errors.file}
              </div>
            )}
          </div>

         

          <div className="button-container">

            <button type="button" className="cancel-button" style ={{backgroundColor:'lightgray'}} onClick={handleCancelClick} >
              Cancel
            </button>
            <button type="submit" className="upload-button">
           
              {loading ? <div className='Signuploader' style ={{}}></div> : 'Upload File'}
            </button>

           
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDocument;
