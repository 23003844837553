import {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import './SignUp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';


const SignUp = () => {
  
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

 
  const formik = useFormik({
    initialValues: {
      Name: '',
      Number: '',
      Email: '',
      Password: '',
      ConfirmPassword: '',
    },
    validationSchema: Yup.object({
      Name: Yup.string().required('Name is required'),
      Number: Yup.string().required('Phone Number is required'),
      Email: Yup.string().email('Invalid email address').required('Email is required'),
      Password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Password must have at least 8 characters')
        .matches(/[0-9]/, 'Your password must have at least 1 digit character')
        .matches(/[a-z]/, 'Your password must have at least 1 lowercase character')
        .matches(/[A-Z]/, 'Your password must have at least 1 uppercase character'),
      ConfirmPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {

        setLoading(true);
        //check if email exists

        const emailCheckResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/checkEmail/${values.Email}`);
     
        if ((emailCheckResponse.data) === true) {

         setErrorMessage('Email already exists');
          // Show toast notification
         toast.error('User Account Already Exists. Log In', {
           position: 'top-right',
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           containerId:'error',
         });

          setTimeout(() => {
            navigate('/');
          }, 4000);
          return;
    
        }
        setErrorMessage();
        await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/createOriginator`, values);
        

        toast.success('We\'ve sent you a link to your email to Verify your account. Check your mail box ',
        {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          containerId:'success'
        });

         navigate ('/');
         resetForm();
      } catch (error) {
        // Handle errors if needed
        console.error('Error:', error);
        setErrorMessage(error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="SignUp">
      <div className="SignUp-Container">
        <img src="/acmi-logo-color.png" alt="Logo" className="logo" />
        <h3  style ={{marginBlock: '4px'}}className="welcome">Sign Up</h3>
        {errorMessage && (<p style ={{color: 'red', textAlign:'center', fontSize: '12px'}}>{errorMessage}</p>)}
        
        <form onSubmit={formik.handleSubmit}>
      
          <div key="Name">
            <p className="formTitle-p">Name / Organization</p>
            <div style ={{marginBlock:'10px'}}></div>
            <div className="form-group">
            <input
              type="text"
              placeholder="Name or Organization Name"
              name="Name"
              autocomplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Name}
              className={`did-floating-input ${formik.values.Name ? 'focused' : ''}`}
            />
            <label className="did-floating-label">Your Name or Organization Name</label>
            <small className="formik-error">{formik.touched.Name && formik.errors.Name}</small>
          </div>
          </div>

          <div key="Number">
            <p className="formTitle-p">Number</p>
            <div style={{ marginBlock: '10px' }}></div>
            <div className="form-group phone-input">
             
            <PhoneInput
              type="text"
              placeholder="Your Phone Number"
              name="Number"
              autocomplete="off"
              value={formik.values.Number || ''}
              onChange={(value) => {
                console.log('PhoneInput value:', value);
                formik.setFieldValue('Number', value); // Use setFieldValue instead of handleChange
              }}
              onBlur={formik.handleBlur}
              onFocus={() => {
                const input = document.querySelector('.phone-input');
                if (input) {
                  input.classList.add('focused'); // Add your focused class
                }
              }}
            />


              <label className="did-floating-label phone-input-label">Your Phone Number</label>
              <small className="formik-error">{formik.touched.Number && formik.errors.Number}</small>
            </div>
        </div>

          <div key ='Email'>
            <p className="formTitle-p">Email</p>
            <div style ={{marginBlock:'10px'}}></div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                name="Email"
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
                className={`did-floating-input ${formik.values.Email ? 'focused' : ''}`}
              />
              <label className="did-floating-label">Your Email</label>
              <small className="formik-error">{formik.touched.Email && formik.errors.Email}</small>
            </div>
          </div>

        <div key = 'Password'>
          <p className="formTitle-p">Password</p>
          <div style ={{marginBlock:'10px'}}></div>
          <div className="form-group">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                autocomplete="off"
                name="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Password}
                className={`did-floating-input ${formik.values.Password ? 'focused' : ''}`}

             
              />
              <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              <label className="did-floating-label">Your Password</label>
             
              <small style ={{fontSize: '9px', textDecoration:'underline'}}>Minimum 8 characters</small> <br />
             
              <small className="formik-error">{formik.touched.Password && formik.errors.Password}</small>
             
            </div>
        </div>

         <div key = 'ConfirmPassword'>
          <p className="formTitle-p">Confirm Password</p>
          <div style ={{marginBlock:'10px'}}></div>
          <div className="form-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                name="ConfirmPassword"
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ConfirmPassword}
                className={`did-floating-input ${formik.values.ConfirmPassword ? 'focused' : ''}`}
              />
                <span className="password-toggle" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </span>
              <label className="did-floating-label">Your Password</label>
              <small style ={{fontSize: '9px', textDecoration:'underline'}}>Minimum 8 characters</small> <br />
              <small className="formik-error">{formik.touched.ConfirmPassword && formik.errors.ConfirmPassword}</small>
            </div>
         </div>

          
          <button type="submit" className="signup-button">
            {loading ? <div className='Signuploader'></div> : 'Sign Up'}
          </button>
        </form>
        
        <NavLink className='Terms-p' to ='/terms-and-conditions'>
            By signing up, you agree to our <span>Terms and Conditions</span>
        </NavLink>
      
        <p>          
          <NavLink to="/" className="nav-link" >
            Sign In
          </NavLink>
        </p>  

        <ToastContainer containerId="error"/>
       
        <ToastContainer containerId="verify-token-expiry"/>
      </div>
    </div>
  );
};

export default SignUp;