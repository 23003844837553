
import {  Routes, Route} from 'react-router-dom';

import  {RequireAuth}  from './context/RequireAuth';
import { AuthProvider } from './context/auth';
import AdminContextProvider from './context/admincontext';
import { ChatContextProvider } from './context/chatContext';
import { ExpansionContextProvider } from './context/expansionContext';
import { CitationsContextProvider } from './context/citationsContext';
import { WorkgroupContextProvider } from './context/workgroupContext';

import SignIn from './components/Authentication/SignIn'
import SignUp from './components/Authentication/SignUp'
import Forget from './components/Authentication/Forget';
import Originator from './components/Dashboard/Originator';
import OriginatorProjectDetail from './components/Dashboard/originatorProjectDetail';
import Admin from './components/Dashboard/Admin';
import Member from './components/Dashboard/Member';
import Partner from './components/Dashboard/Partner';
import AddDocument from './components/Dashboard/Workgroup/AddDocument';



import SuperAdmin from './components/SuperAdmin/SuperAdmin';

import AddProject from './components/Dashboard/AddProject';
import Dashboard from './scenes/dashboard';
import Team from './scenes/team';
import Workgroup from './scenes/workgroup';
import Project from './scenes/project';
import Originators from './scenes/originator';
import StageList from './scenes/createStage/stageList';
import CreateStage from './scenes/createStage';
import CreatePartner from './scenes/createPartner';
import CreateMember from './scenes/createMember';
import CreateDocument from './scenes/createDocument';
import Document from './scenes/document';
import CreateWorkgroup from './scenes/workgroup/createWorkgroup';
import Unauthorized from './components/Authentication/Unauthorized';
import WorkgroupDetails from './scenes/workgroup/workgroupDetails';
import Verification from './components/Authentication/Verification';
import VerificationSuccess from './components/Authentication/VerificationSuccess';
import CreateDocumentStage from './scenes/createDocumentStage';
import ResetPassword from './components/Authentication/Reset';
import StageDocument from './scenes/createStage/stageDocument';
import AddAdmin from './scenes/workgroup/addAdmin';
import Stakeholders from './scenes/workgroup/stakeholders';
import RequestNewToken from './components/Authentication/RequestNewToken';
import AICitations from './components/AIChat/AICitations';
import AI from './components/AIChat/AI';
import AIWorkgroup from './components/AIChatWorkgroup/AI';
import Terms from './components/Authentication/Terms';
import Trial from './components/Dashboard/Trial';



function App() {

  return (


      <div className="App">
    
     <AuthProvider>
      <AdminContextProvider>
        <WorkgroupContextProvider>
          <ChatContextProvider>
            <CitationsContextProvider>
              <ExpansionContextProvider>
                <Routes>
                    
                    <Route path="/" element={<SignIn />} />
                    <Route path="/signUp" element={<SignUp />} />
                    <Route path="/forget" element={<Forget />} />
                    <Route path="/requestNewToken" element={<RequestNewToken/>} />
                    <Route path="/terms-and-conditions" element={<Terms />} />
                  
                    <Route path="/superadmin/*" element={<RequireAuth allowedRoles={['superAdmin']}><SuperAdmin /></RequireAuth>}>
                      <Route path="" element={<Dashboard />} /> {/* A nested route! */}
                      <Route path="team" element={<RequireAuth allowedRoles={['superAdmin']}><Team /></RequireAuth>} /> {/* A nested route! */}
                      <Route path="workgroup" element={<RequireAuth allowedRoles={['superAdmin']}><Workgroup /></RequireAuth>} />
                      <Route path="projects" element={<RequireAuth allowedRoles={['superAdmin']}><Project /></RequireAuth>} />
                      <Route path="originators" element={<RequireAuth allowedRoles={['superAdmin']}><Originators /></RequireAuth>} />
                      <Route path="createPartner" element={<RequireAuth allowedRoles={['superAdmin']}><CreatePartner /></RequireAuth>} />
                      <Route path="createStage" element={<RequireAuth allowedRoles={['superAdmin']}><CreateStage /></RequireAuth>} />
                      <Route path="stages" element={<RequireAuth allowedRoles={['superAdmin']}><StageList /></RequireAuth>} />
                      <Route path="documents" element={<RequireAuth allowedRoles={['superAdmin']}><Document /></RequireAuth>} />
                      <Route path="createWorkgroup" element={<RequireAuth allowedRoles={['superAdmin']}><CreateWorkgroup/></RequireAuth>} />
              
                      <Route path="addWorkgroupMember/:id" element={<RequireAuth allowedRoles={['superAdmin']}><CreateMember /></RequireAuth>} />
                      <Route path="addWorkgroupDocument/:id/:Wname" element={<RequireAuth allowedRoles={['superAdmin']}><CreateDocument /></RequireAuth>} />
                      <Route path="addStageDocument/:id/:Sname" element={<RequireAuth allowedRoles={['superAdmin']}><CreateDocumentStage /></RequireAuth>} />
                      <Route path='stageDocuments' element={<RequireAuth allowedRoles={['superAdmin']}><StageDocument /></RequireAuth>} />
                      <Route path="addWorkgroupAdmin/:id" element={<RequireAuth allowedRoles={['superAdmin']}><AddAdmin /></RequireAuth>} />
                      <Route path='stakeholders' element={<RequireAuth allowedRoles={['superAdmin']}><Stakeholders /></RequireAuth>} />
                    </Route>
                  

                      
                      <Route path="/developer/*" element={<RequireAuth allowedRoles={['originator']}><Originator/></RequireAuth>}>
                          <Route path="" element={<AI/>} />
                          <Route path="citations/:promptId" element={<AICitations />}/>      
                      </Route>

                      <Route path="/member" element={<RequireAuth allowedRoles={['member']}><Member/></RequireAuth>}>
                          <Route index element={<AIWorkgroup/>} />
                          <Route path="citations/:promptId" element={<AICitations />}/>      
                      </Route>

                      <Route path="/admin" element={<RequireAuth allowedRoles={['admin']}><Admin/></RequireAuth>}>
                          <Route index element={<AIWorkgroup/>} />
                          <Route path="citations/:promptId" element={<AICitations />}/>      
                      </Route>

                      <Route path="/partner" element={<RequireAuth allowedRoles={['partner']}><Partner/></RequireAuth>}>
                          <Route index element={<AIWorkgroup/>} />
                          <Route path="citations/:promptId" element={<AICitations />}/>      
                      </Route>
                    
                    <Route path ="/developer/addProject" element={<RequireAuth allowedRoles={['originator']}><AddProject/></RequireAuth>}/>
                    <Route path="/originatorProjectDetail/:id/:projectID/:projectName/:stageName" element={<RequireAuth allowedRoles={['originator']}><OriginatorProjectDetail/></RequireAuth>}/>
                    <Route path ="/addDocument/pending/:id/:Wname" element={<RequireAuth allowedRoles={['member', 'admin']}><AddDocument /></RequireAuth>}/>



                    <Route path="/unauthorized" element={<Unauthorized/>} />
                    <Route path="/verify/:token" element={<VerificationSuccess/>} />

                    <Route path="/verifyAccount/:token" element={<Verification/>} />

                    <Route path="/resetPassword/:token" element={<ResetPassword />} />

                    <Route  path ='/trial'  element = {<Trial/>}/>


                      
                </Routes>
             </ExpansionContextProvider>
          </CitationsContextProvider>
       </ChatContextProvider>
      </WorkgroupContextProvider>
    </AdminContextProvider>
  </AuthProvider>


     
    </div>
    
  );
}

export default App;
