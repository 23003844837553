import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { useNavigate } from 'react-router-dom'; 
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();
  const auth = useAuth();

  const handleLogout = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`);
    auth.logout();
    navigate('/');
    
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
    
      <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="3px"
      p={2} 
    >
      <img src="/acmi-logo-color.png" alt="Logo" className="logo" style ={{marginBottom:'0px', maxHeight: '40px'}}/>
      
    </Box>

    <Box>
      <Typography variant="h2" sx={{ color: colors.primary[500], fontWeight:'bold' }}>
        ACMI SELF GUIDING PORTAL
      </Typography>
    </Box>


      {/* ICONS */}
      <Box display="flex">
       { <IconButton onClick={colorMode.toggleColorMode} sx={{ color: colors.primary[500] }}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>}
     
        <IconButton sx={{ color: colors.primary[500] }}>
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton onClick ={handleLogout} sx={{ color: colors.primary[500] }}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;