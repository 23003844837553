import React, { useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ChatContext } from '../../context/chatContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AIStages() {

  const { stage, setStage, resetAIChat} = useContext(ChatContext);
  const isStageSelected = stage !== '';

  const selectRef = useRef(null);

  /*
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [prompts]);
  */

  const handleStageChange = (e) =>{
    setStage(e.target.value)
  }

  const handleReset = (e) =>{
    e.preventDefault(); 
    setStage('')
    toast.success("Chat Reset: Please select a new project stage to start a new chat.", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,

    });
    resetAIChat();
  }


  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0', paddingRight:'21px' }}>

      <select
        ref={selectRef}
        value={stage}
        onChange={handleStageChange}
        style={{
          padding: '12px',
          border: '2px solid lightgray',
          fontFamily:'Poppins',
          fontSize:'12px',
          fontWeight:'600',
          color:'#333333',
          height: '45px',
          width: isStageSelected ? '80%' : '100%', 
          borderRadius: '8px',
           
        }}
      >
    
    
        <option value="" disabled selected>Please Select Project Stage</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Design" title="Ask me questions on project design" style={{ cursor: 'help'}} >Project Design</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option> 
        <option value="Validation" title="Ask me questions on project validation" style={{ cursor: 'help'}}>Project Validation</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option> 
        <option value="Registration" title="Ask me questions on project registration" style={{ cursor: 'help'}}>Project Registration</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Implementation" title="Ask me questions on project implementation" style={{ cursor: 'help'}}>Project Implementation</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Monitoring" title="Ask me questions on project monitoring" style={{ cursor: 'help'}}>Project Monitoring</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Verification" title="Ask me questions on project verification" style={{ cursor: 'help'}}>Project Verification</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Issuance" title="Ask me questions on project issuance" style={{ cursor: 'help'}}>Issuance</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
        <option value="Sale-and-retirement" title="Ask me questions on project sale and retirement" style={{ cursor: 'help'}}>Sale and Retirement</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>
       
        <option value="General" title="Ask me general questions on carbon markets" style={{ cursor: 'help', fontWeight:'bolder'}}>General</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>


      </select>

      {isStageSelected && (
        <button
          onClick={handleReset}
          type ="button"
          style={{
            height: '45px',
            backgroundColor: '#F87524',
            color: 'white',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            width: '19%',
            marginLeft: '3px',
            fontWeight:'600',
            fontSize:'12px',
            paddingInline:'23px',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            
          }}
        >
        <FontAwesomeIcon icon={faTimes}  style={{ marginRight: '5px' }}  /> Reset
        </button>
      )}
      <ToastContainer position="top-right"  />

    </div>
  );
}

export default AIStages;
