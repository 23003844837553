import { useAuth } from './auth';
import { Navigate } from 'react-router-dom';

export const RequireAuth = ({ children, allowedRoles }) => {
  const auth = useAuth();

  if (!auth.user || !localStorage.getItem('USER_SGP')) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/" />;
  }

  if (!allowedRoles || allowedRoles.length === 0 || !allowedRoles.includes(auth.user.role)) {
    
    return <Navigate to="/unauthorized" />;
  }
  return children;
};






