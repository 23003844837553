import React from 'react';
import Modal from 'react-modal';
import './ModalComponent.css';

const ModalComponent = ({ isOpen, onRequestClose, handleApproveDocument,  handleDeleteDocument }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmation Modal"
      className="custom-modal-content"
      overlayClassName="custom-modal-overlay"
    >
      <div>
        <h2 className="modal-title">Approve Document</h2>
        <p className="modal-text">Are you sure you want to approve the document?</p>
        <div className="modal-buttons">
          <button className="modal-button modal-no" onClick={onRequestClose}>
            Cancel
          </button>

          <button className="modal-button modal-delete" onClick={handleDeleteDocument}>
            Delete Document
          </button>
          <button className="modal-button modal-yes" onClick={handleApproveDocument}>
            Yes, Approve
          </button>

        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
