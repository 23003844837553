import './Originator.css';
import {NavLink} from "react-router-dom";
import { useAuth } from '../../context/auth';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export default function OriginatorTop({ userRole }) {
    JSON.parse(localStorage.getItem("USER_SGP"));
    const { user } = useAuth();
    
    return (
      <div className="Top">
        <div className="Left">
    
        <p style={{ marginTop: userRole === 'partner' ? '25px' : '0' }}>Hi, {user.user.Name}</p>

  
          {userRole === 'originator' && <button><p>Project Developer</p></button>}
          {userRole === 'admin' && <button><p>Workgroup Admin</p></button>}
          {userRole === 'member' && <button> <p>Workgroup Member</p></button>}
         
        </div>
        <div className="Right">
          {userRole === 'originator' && (
            <button>
              
              <ControlPointIcon style={{ fontSize: '20px' }}/>
              <NavLink to="/developer/addProject" className="nav-link" activeClassName="active-nav-link">
                Add Project
              </NavLink>
            </button>
          )}

          {userRole === 'partner' && <button ><p>ACMI Sponsor</p></button>}
         
         
        </div>
      </div>
    );
  }
  