import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { WorkgroupContext } from '../../../context/workgroupContext';
import ModalComponentAdminInfo from './ModalComponentAdminInfo';

const DefaultWorkgroup = () => {
  const { defaultWorkgroup, loading } = useContext(WorkgroupContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return <div className ='Workgroup_Loading_Container'><div className='loader'></div></div>;
  }

  return (
    defaultWorkgroup && (
      <div>
        <h3 style={{ color: '#0E8DC6' }}>{defaultWorkgroup.WorkgroupName}</h3>
        <div className="Admin_Title">
          <div className="Agenda_Desc" style={{ backgroundColor: '#F37326', justifyContent: 'space-between' }}>
            <button> Workgroup Agenda </button>
            <p style={{ color: 'white', fontSize: '12px', lineHeight: '2' }}>{defaultWorkgroup.About}</p>
            <ArrowForwardIosIcon style={{ marginTop: '6px', color: 'white', fontSize: '13px', border: '2px solid white', borderRadius: '50%', padding: '5px' }} />
          </div>

          <div className="Members" style={{ backgroundColor: '#EAF2FA', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={{ borderTop: '3px solid #0E8DC6', borderBottom: '3px solid #0E8DC6', color: '#0E8DC6' }}>
              {defaultWorkgroup.memberCount}
            </h1>
            <p style={{ color: '#0E8DC6', fontWeight: '600' }}> {defaultWorkgroup.memberCount === 1 ? 'Member' : 'Members'}</p>
          </div>

          <div className="Documents" style={{ backgroundColor: '#EDF8EC', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={{ borderTop: '3px solid #80CC7B', borderBottom: '3px solid #80CC7B', color: '#80CC7B' }}>
              {defaultWorkgroup.documentCount}
            </h1>
            <p style={{ color: '#80CC7B', fontWeight: '600' }}>Documents</p>
          </div>

          <div onClick={openModal} className="Demo" style={{ backgroundColor: '#FEF8E5', justifyContent: 'space-between', alignItems: 'center' }}>
            <button style={{ marginTop: '22px' }}>Demo Admin</button>
            <NavLink style={{ color: '#0087c3' }}>Contact</NavLink>
            <p style={{ fontWeight: '600' }}>Admin</p>
          </div>
        </div>

        <ModalComponentAdminInfo isOpen={isModalOpen} onRequestClose={closeModal} />
      </div>
    )
  );
};

export default DefaultWorkgroup;

