import React, { useEffect, useState } from 'react';
import { tokens } from "../../theme";
import json_data from '../../upload/extracted_data_final.json';
import {useTheme,  Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem } from '@mui/material';
import AddWorkgroupDocument from './addWorkgroupDocument';

const Stakeholders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [csvData, setCSVData] = useState('');
  const [selectedRole, setSelectedRole] = useState('All');
 
  const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);

  const handleOpenAddDocumentModal = () => {
    setAddDocumentModalOpen(true);
  };

  const handleCloseAddDocumentModal = () => {
    setAddDocumentModalOpen(false);
  };


  // Define the CSV header
  const csvHeader = 'Proponent Name, Organization, Location, Role, Contact, Email';

  // Function to convert JSON data to CSV format
  const convertJSONtoCSV = (jsonData, filterRole) => {
    let csvContent = csvHeader + '\n';

    jsonData.forEach((entity) => {
      const isNone = Object.values(entity).flat().some((value) => value === 'None');

      if (!isNone) {
        const proponent = entity.proponent || {};
        const otherEntities = entity.other_entities === 'None' ? {} : entity.other_entities || {};
        const propName = (proponent.name || '').split(',')[0] || '';
        const orgName = (otherEntities.organization || '').split(',')[0] || '';
        const location = (entity.location || '');
        const role = (otherEntities.role || '').split(',')[0] || '';
        const contact = (otherEntities.contact || '').split(',')[0] || '';
        const email = (otherEntities.email || '').split(',')[0] || '';
 

        if (filterRole === 'All' || role === filterRole) {
          const csvRow = `${propName},${orgName},${location},${role},${contact},${email}`;
          csvContent += csvRow + '\n';
        }
      }
    });

    return csvContent;
  };

  // Convert JSON data to CSV and set it in the state
  useEffect(() => {
    const generatedCSV = convertJSONtoCSV(json_data, selectedRole);
    setCSVData(generatedCSV);
  }, [ selectedRole]);

  return (
    <div>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
      {/* Role filter dropdown */}
      <Select
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
        style={{ marginBottom: '10px' }}
      >
        <MenuItem value="All">All Stakeholders</MenuItem>
        <MenuItem value="DMRV">DMRV</MenuItem>
        <MenuItem value="VVB">VVB</MenuItem>
        <MenuItem value="Buyer">Buyer</MenuItem>
        <MenuItem value="Investor">Investor</MenuItem>
        <MenuItem value="community">Community</MenuItem>
        <MenuItem value="Carbon Registry">Carbon Registry</MenuItem>
        {/* Add more role options as needed */}
      </Select>

        <Box>
              
         <Button 
             variant="outlined"
             onClick={handleOpenAddDocumentModal}
             sx={{
               borderColor: colors.blueAccent[700],
               color: colors.grey[100],
             }}
           >
           Upload Stakeholder Document
           </Button>
      
        </Box>
      </Box>

      {/* Display the CSV data in a styled table */}
      <TableContainer component={Paper} style={{ marginTop: '10px' }}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {csvData.split('\n').map((row, index) => (
              // Split CSV rows and map each row to a table row
              <TableRow key={index} style={{ borderBottom: '1px solid #ddd' }}>
                {row.split(',').map((cell, cellIndex) => (
                  // Split CSV cells and map each cell to a table cell
                  <TableCell key={cellIndex} style={{ padding: '8px', textAlign: 'left', width:'15%' }}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddWorkgroupDocument
        isOpen={isAddDocumentModalOpen}
        onClose={handleCloseAddDocumentModal}
      />
    </div>
  );
};

export default Stakeholders;
