import { CitationsContext } from "../../context/citationsContext";
import { ChatContext } from "../../context/chatContext";
import { useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

const AICitations = () => {
    const navigate = useNavigate();
    const { promptId } = useParams();
    const { citations, setId, setSelectedCitation, selectedCitation } = useContext(CitationsContext);
    const {stage} = useContext(ChatContext);
    const scrollRef = useRef(null);

    useEffect(() => {
        setId(promptId);
        // Scroll to the top when component mounts
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [promptId, setId]);

    const handleClick = (citation) => {
        setSelectedCitation(citation);
    };

    const goBack = () => {
        navigate(`/developer`);
    };

    return (
        <>
            <section>
                <div className='Section_Header'>
                    <h2 style={{ fontSize: '16px' }}>Citations</h2>
                    <button onClick={goBack}>
                        Back to AI Chat
                    </button>

                 
                </div>

                <div>
                    <div className="Citation_List">
                        {citations.map((citation, index) => (
                            <div
                                key={index}
                                onClick={() => handleClick(citation)}
                                className={`citation-item ${selectedCitation === citation ? 'selected' : ''}`}
                            >
                                <div>
                                    {citation.file_name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <div ref={scrollRef}>
                {selectedCitation ? (
                    <>
         
                        <div className='Iframe_Container'>
                            <p key={selectedCitation._id} className='Iframe_Text'>{selectedCitation.file_name} </p>
                            <button className='Iframe_Button'>
                                <a href={`https://acmi-sgp.s3.amazonaws.com/${stage}/${selectedCitation.file_name}`} target="_blank" rel="noopener noreferrer">Download the whole document</a>
                            </button>

                        </div>

                        <div>
                            <iframe
                                src={selectedCitation.file_path}
                                title={selectedCitation.file_name}
                                className="Iframe"
                                style={{ zoom: "100%" }}
                            ></iframe>
                        </div>
                   
                    </>

                ) : (
                    <p className="text-center font-bold text-sm">This message has no citation.</p>
                )}

            </div>
        </>
    )
}

export default AICitations;
