import Header from './Header'
import OriginatorTop from './OriginatorTop';
import AIWorkgroup from '../AIChatWorkgroup/AI';
import './Originator.css';
import './Admin.css'; 
import './Workgroup/WorkGroup';
import WorkGroup from './Workgroup/WorkGroup';
import { useState, useEffect, useContext } from 'react';
import {  Routes, Route } from "react-router-dom";
import { ExpansionContext } from '../../context/expansionContext';
import AICitations from '../AIChatWorkgroup/AICitations';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function Partner() {
  JSON.parse(localStorage.getItem("USER_SGP"));
    let userRole = 'partner';
  
    const {open, setButtonClicked, buttonClicked} = useContext(ExpansionContext);
    const [isExpanded, setIsExpanded] = useState(false);


    useEffect(() => {


      if (open) {
        if(buttonClicked) {
          setIsExpanded(true);
        } 

        if (!buttonClicked){
          setIsExpanded(false)
        }
       
      } 
     
    }, [open, buttonClicked]);
    
  
    return (
      <div>
        <Header userRole={userRole} />
        <OriginatorTop userRole={userRole} />
  
        <div className="Main">
          <div className={`Main-Left ${isExpanded ? 'shrink' : ''}`}>
            <WorkGroup  />
          </div>
  

          <div className={`Main-Right ${isExpanded ? 'shrink' : ''}`} style ={{position: 'relative'}}>
            <>
               <div style={{position:'absolute', top:'50%', left:'0px'}}>
                  {buttonClicked && (
                    <ArrowForwardIosIcon onClick ={() => setButtonClicked(!buttonClicked)} style ={{fontSize: '15px', color: '#0087c3'}}/>
                  )}

                  {!buttonClicked && (
                    <ArrowBackIosIcon onClick ={() => setButtonClicked(!buttonClicked)} style ={{fontSize: '15px', color: '#0087c3'}}/>
                  )}
             </div>
             <Routes>
               <Route path="/" element={<AIWorkgroup userRole={userRole}  />} />
               <Route path="/citations/:promptId" element={<AICitations />} />
             </Routes>
            </>
          </div>


        </div>
      </div>
    );
  }
  