import {useState} from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import './SignUp.css';

const Forget = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      Email: '',
      
    },
    validationSchema: Yup.object({
      Email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        const emailCheckResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/checkEmail/${values.Email}`);

        if (emailCheckResponse.data === false) {
          setErrors({
            Email: 'Email not found.',
          });

          setError('Email not found. Please enter a valid email address.');
          return;
        }


        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/forgetPassword/${values.Email}`);
        
        resetForm();

        toast.success('We\'ve sent you a link to verify your email. Check your mail box ',{
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          containerId:'success-forget'
        });
        
        navigate('/');// Navigate to your desired route after successful submission
      } catch (error) {
        // Handle errors if needed
        console.error('Error:', error);
        setError('There was an error in sending the email. Please try again');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="SignUp">
      <div className="SignUp-Container">
        <div style ={{display: 'flex', flexDirection: 'column', marginTop:'20px'}}>
        <img src="/acmi-logo-color.png" alt="Logo" className="logo" />
        <h3 style ={{marginBlock: '4px'}} className="welcome">Password Reset</h3>
        {error && (<p style ={{color: 'red', textAlign:'center', fontSize: '12px'}}>{error}</p>)}
        </div>
        <br />
        <form onSubmit={formik.handleSubmit}>

          <div key ='Email'>
            <p className="formTitle-p">Email</p>
            <div style ={{marginBlock:'10px'}}></div>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email"
                name="Email"
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
                className={`did-floating-input ${formik.values.Email ? 'focused' : ''}`}
              />
              <label className="did-floating-label">Your Email</label>
              <small className="formik-error">{formik.touched.Email && formik.errors.Email}</small>
            </div>
          </div>
          
          <button type="submit" className="signup-button">
            Reset Password
          </button>
        </form>
        

      </div>
    </div>
  );
};

export default Forget;