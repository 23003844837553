import { Box, useTheme, Button, Modal, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/SuperAdmin/Header";
import { useEffect, useState, useContext} from "react";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminContext } from "../../context/admincontext";
import  CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const Document = () => {
  const {user} = useAuth();
  const userRole = user.user.UserRole;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({});
  const { disallowApproval, setDisallowApproval } = useContext(AdminContext);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/document`);
      // Filter documents where stageID is not null
      const filteredDocuments = res.data.documents.filter(document => document.workgroupID !== null);
      setDocuments(filteredDocuments);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };
 

  const actionsColumn = globalSettings.disallowApproval === false 
  ? {
      field: "Approved",
      headerName: "Approved",
      flex: 0.8,
      renderCell: ({ row }) => (
        row.Approved ? (
          <Button  disabled>
              < CheckCircleOutlineIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
          </Button>
      ) : (
          userRole === 'superAdmin' ? (
              <Button
                  variant="outlined"
                  onClick={() => {
                      setSelectedDocumentId(row._id);
                      setIsConfirmationModalOpen(true);
                  }}
                  style={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }}
              >
                  Approve
              </Button>
          ) : null
      )
      ),
    }
  : {};

  const columns = [
    {
      field: 'sequence',
      headerName: '#',
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          {row.sequence}
        </div>
      ),
    },
    {
      field: 'image',
      headerName: 'File',
      flex: 2.5,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => (
        <a href={row.image} target="_blank" rel="noopener noreferrer">
          {row.image}
        </a>
      ),
    },
   
    
    { field: "workgroupName", headerName: "Workgroup Name", flex: 1, cellClassName: "name-column--cell",  filterable: true },
    {
      field: "UploadDate",
      headerName: "Upload Date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: ({ row }) => (
        <div>{new Date(row.UploadDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</div>
      ),
    },
    actionsColumn,
    {
        field: "Delete",
        headerName: "Delete",
        flex: 0.5,
        renderCell: ({ row }) => (
          userRole === 'superAdmin' && row.Approved? (
            <Button
            
              onClick={() => {
                setSelectedDocumentId(row._id);
                setIsDeleteConfirmationModalOpen(true);
              }}
            >
        
              < DeleteOutlineIcon
                  sx={{ color: colors.redAccent[600], fontSize: "26px" }}
              />
            </Button>
          ) : null
        ),
      }

    
  ];

  const handleApproveDocument = async () => {
    try {
      // Make an API call to update the document's approval status
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/document/approveDocument/${selectedDocumentId}`, {
        Approved: true,
      });

      // Close the confirmation modal
      setIsConfirmationModalOpen(false);
      await getApprovedDocument();
      // Refresh the data after approval
      fetchDocuments();
      toast.success('You have successfully approved the document', {
        position: 'top-right',
        autoClose: 3000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error approving document:', error);
    }
  };

  const getApprovedDocument = async () =>{
    const approvedDocument = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/document/${selectedDocumentId}`);
  

    const filename = approvedDocument.data.image;
    const context = approvedDocument.data.context; 

    await axios.post('https://staging-bayesnet.bayesconsultants.com/api/acmi/upload', {
      context: context,
      filename: filename,
    });
    
  }

  const handleOpenDeleteModal = (document) => {
    setSelectedDocumentId(document._id);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedDocumentId(null);
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteDocument = async () => {
    try {
      // Check if a document is selected
      if (!selectedDocumentId) {
        // Handle the case where there is no selected document
        return;
      }
      await getDeletedDocument();
      // Implement your delete logic here, for example, using axios
     await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/document/${selectedDocumentId}`);

      // Close the delete confirmation modal after deletion
      handleCloseDeleteModal();

      // Refresh the data after deletion
      fetchDocuments();
      toast.success('You have successfully deleted the document', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      // Handle error if needed
      console.error('Error deleting document:', error);
    }
  };

  const getDeletedDocument = async () => {

    try {
   
      // Make an API call to get the deleted document details
      const deletedDocument = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/document/${selectedDocumentId}`);
      

      // Extract filename and context from the deleted document
      const filename = deletedDocument.data.image;
      const context = deletedDocument.data.context;

      // Make an API call to delete the document from the external system
      await axios.delete('https://staging-bayesnet.bayesconsultants.com/api/acmi/delete', {
        data: {
          context: context,
          filename: filename,
        },
      });
    } catch (error) {
      console.error('Error getting or deleting the deleted document:', error);
    }
  };



  useEffect(() => {
    // Fetch global settings when the component mounts
    const fetchGlobalSettings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/globalSettings`);
            setGlobalSettings(response.data);
        } catch (error) {
            console.error('Error fetching global settings:', error);
        }
    };

    fetchGlobalSettings();
}, []);


const handleToggleApproval = async () => {
  try {
      // Update the global setting
      await axios.put(`${process.env.REACT_APP_BASE_URL}/v1/globalSettings`, {
          disallowApproval: !globalSettings.disallowApproval,
      });

      // Update the local state
      setGlobalSettings((prevSettings) => ({
          ...prevSettings,
          disallowApproval: !prevSettings.disallowApproval,
      }));
      setDisallowApproval(!globalSettings.disallowApproval);
  } catch (error) {
      console.error('Error updating global settings:', error);
  }
};


  return (
    <Box m="20px">
      <Box sx= {{display: "flex", justifyContent:"space-between"}}>
        <Header title="DOCUMENTS" subtitle="Managing Documents" />
    
      
        <Button  onClick={handleToggleApproval} variant="contained"  sx={{height:"40px", backgroundColor:colors.primary[500]}}>
          {globalSettings.disallowApproval ? 'Allow Document Approval' : 'Disallow Document Approval'}
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[500],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={documents.map((document, index) => ({ ...document, sequence: index + 1 }))}
          columns={columns}
          getRowId={(row) => row._id}
        />

       

        <Modal
          open={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: 400,
              p: 4,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Confirm Approval
            </Typography>
            <Typography variant="body2" color='#555555'>
              Are you sure you want to approve the document?
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end" gap="10px">
              
              <Button onClick={() => setIsConfirmationModalOpen(false)}
                sx={{ 
                    
                    backgroundColor:'white', 
                    color:'#555555',
                    '&:hover':{
                      backgroundColor:'#555555',
                      color:'white'
                    }
                    
                    }}
                    >
                Cancel
              </Button>

              <Button variant="contained" onClick={handleApproveDocument} color="error">
                Yes, Approve
              </Button>
            </Box>
          </Box>
        </Modal>

        {isDeleteConfirmationModalOpen && (
        <Modal
          open={isDeleteConfirmationModalOpen}
          onClose={handleCloseDeleteModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: 400,
              p: 4,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Confirm Deletion
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Are you sure you want to delete this document?
            </Typography>
            <Box mt={2} display="flex" justifyContent="flex-end" alignItems="flex-end" gap="10px">
              <Button variant="outlined" onClick={handleCloseDeleteModal} color="secondary">
                No
              </Button>
              <Button variant="contained" onClick={handleDeleteDocument} color="error">
                Yes, Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Document;
