import {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './SignUp.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = () => {
 
  const navigate = useNavigate();
  const {token} = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  
  const formik = useFormik({
    initialValues: {
      Password: '',
      ConfirmPassword: '',
    },
    validationSchema: Yup.object({
      Password: Yup.string()
        .required('Please enter a password')
        .min(8, 'Password must have at least 8 characters')
        .matches(/[0-9]/, 'Your password must have at least 1 digit character')
        .matches(/[a-z]/, 'Your password must have at least 1 lowercase character')
        .matches(/[A-Z]/, 'Your password must have at least 1 uppercase character'),
      ConfirmPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        
        await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/auth/resetPassword/${token}`, {
          newPassword: values.Password,
        });

 
          resetForm();

          toast.success('You have successfully reset your password, you can now log in', { 
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            containerId:'success-reset'
          });
  
          navigate('/');
        
        
      
       
        
      } catch (error) {
           if (error.response.data.msg === 'Try later'){

            setErrors({
              general: 'Error resetting password. Please try again later.',
            });
           }
       
      

            setErrors({
              general: 'Token has expired. Please request a new verification token.',
            });

            toast.error('Verification token has expired.', {
              position: 'top-right',
              autoClose: 3000, 
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              containerId: 'toast-container-reset-failure', 
            });

            navigate ('/requestNewToken')
          
          
      
        
      } finally {
        setSubmitting (false); 
      }
    },
  });

  return (
    <div className="SignUp">
      <div className="SignUp-Container">
        <img src="/acmi-logo-color.png" alt="Logo" className="logo" />
        {formik.errors.general && (
            <div className="formik-error" style ={{ fontSize:'12px'}}>
              {formik.errors.general}
            </div>
          )}
        <h3 className="welcome">Enter your new Password</h3>

      
        <form onSubmit={formik.handleSubmit}>

        <div key = 'Password'>
          <p className="formTitle-p">Password</p>
          <div style ={{marginBlock:'10px'}}></div>
          <div className="form-group">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                name="Password"
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Password}
                className={`did-floating-input ${formik.values.Password ? 'focused' : ''}`}
              />
              <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
              </span>
              <label className="did-floating-label">Your Password</label>
              <small style ={{fontSize: '9px', textDecoration:'underline'}}>Minimum 8 characters</small> <br />
              <small className="formik-error">{formik.touched.Password && formik.errors.Password}</small>
             
            </div>
        </div>

         <div key = 'ConfirmPassword'>
          <p className="formTitle-p">Confirm Password</p>
          <div style ={{marginBlock:'10px'}}></div>
          <div className="form-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                name="ConfirmPassword"
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ConfirmPassword}
                className={`did-floating-input ${formik.values.ConfirmPassword ? 'focused' : ''}`}
              />
              <span className="password-toggle" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </span>
              <label className="did-floating-label">Your Password</label>
              <small style ={{fontSize: '9px', textDecoration:'underline'}}>Minimum 8 characters</small> <br />
              <small className="formik-error">{formik.touched.ConfirmPassword && formik.errors.ConfirmPassword}</small>
            </div>
         </div>

          
          <button type="submit" className="signup-button">
            Reset Password
          </button>
        </form>

       
        <ToastContainer containerId="toast-container-reset-failure" /> 
        
      </div>
    </div>
  );
};

export default ResetPassword;