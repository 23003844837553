import { useState, useEffect, createContext } from 'react';
import axios from 'axios'; 
import { useAuth } from './auth';

export const WorkgroupContext = createContext();

export const WorkgroupContextProvider = ({ children }) => {
    const { user } = useAuth();
    const [workgroups, setWorkgroups] = useState([]);
    const [error, setErrors] = useState(null);
    const [defaultWorkgroupID, setDefaultWorkgroupID] = useState(null); 
    const [defaultWorkgroup, setDefaultWorkgroup] = useState(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            await fetchWorkgroups();
            if (user && user.user && user.user._id) {
                await fetchUserWorkgroup(user.user._id);
            }else {
                setDefaultWorkgroup(null)
                setDefaultWorkgroupID(null)
            }
        }

        fetchData();
    }, [user]);

    useEffect(() => {
        if (defaultWorkgroupID) {
            fetchWorkgroupDetails(defaultWorkgroupID); 
        }
    }, [defaultWorkgroupID]);

    const fetchWorkgroups = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/`);
            setWorkgroups(response.data.Workgroups);
        } catch (error) {
            setErrors(error.response.data);
        }
    }

    const fetchUserWorkgroup = async (userId) => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/find/${userId}`);
            setDefaultWorkgroupID(response.data.workgroupId);
        } catch (error) {
            setErrors(error.response.data);
        }finally{
            setLoading(false)
        }
    }

    const fetchWorkgroupDetails = async (workgroupId) => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/findWorkgroup/${workgroupId}`);
            setDefaultWorkgroup(response.data);
        } catch (error) {
            setErrors(error.response.data);
        }finally{
            setLoading(false)
        }
    }

    //Add a Document 

    const addDocument = async () =>{
        fetchWorkgroupDetails(defaultWorkgroupID); 
    }

    //Delete a document 
    const deleteDocument = async (documentId) =>{
        try {
          await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/document/${documentId}`);
          fetchWorkgroupDetails(defaultWorkgroupID); 
        } catch (error) {
            setErrors(error.response.data);
        }
    }


    const approveDocument = async (documentId) =>{
        try{
            const updatedDocument = await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/document/approveDocument/${documentId}`);
            
            const documentContext = updatedDocument.data.context;
            const documentFilename = updatedDocument.data.image;

        
            try {
              await axios.post('https://staging-bayesnet.bayesconsultants.com/api/acmi/upload', {
                context: documentContext,
                filename: documentFilename,
              });

              fetchWorkgroupDetails(defaultWorkgroupID); 
            }catch(error){
              console.log(error.response.data)
            }
          }catch (error){
            console.log(error.response.data)
          }

    }


    return (
        <WorkgroupContext.Provider value={{loading,  error, workgroups, defaultWorkgroupID, defaultWorkgroup, setDefaultWorkgroupID, deleteDocument, addDocument, approveDocument  }}>
            {children}
        </WorkgroupContext.Provider>
    );
}
