import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faZap, faShield, faUser, faGear, faFileAlt, faCircleCheck, faDollarSign, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import json_data from '../../upload/extracted_data_final.json';
import './BoxComponent.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BoxComponent = ({ selectedStage }) => {
  const [cleanedData, setCleanedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRole, setSelectedRole] = useState('All');

  useEffect(() => {
    // Map selectedStage to default roles
    const roleMap = {
      Registration: 'community',
      Validation: 'VVB',
      Verification: 'VVB',
      Implementation: 'Developer',
      Monitoring: 'Developer',
      Issuance: 'Carbon Registry',
      Design: 'Developer',
      'Sale&Retirement': 'Buyer',
    };

    // Set the default role based on the selectedStage
    setSelectedRole(roleMap[selectedStage] || 'All');
  }, [selectedStage]);

  useEffect(() => {
    const cleanedData = json_data.filter((organization) => organization.proponent && organization.proponent.name);
    setCleanedData(cleanedData);
    filterDataByRole(selectedRole, cleanedData);
  }, [selectedRole]);

  useEffect(() => {
    filterDataByRole(selectedRole, cleanedData);
  }, [cleanedData]);

  const filterDataByRole = (role, data) => {
    if (role === 'All') {
      setFilteredData(data.filter((entry) => entry.proponent && entry.other_entities));
    } else {
      const filteredData = data.filter((organization) => {
        return (
          organization.proponent &&
          organization.other_entities &&
          typeof organization.proponent === 'object' &&
          typeof organization.other_entities === 'object' &&
          organization.proponent !== 'None' &&
          organization.other_entities !== 'None' &&
          organization.other_entities.role === role
        );
      });
      setFilteredData(filteredData);
    }
  };

  useEffect(() => {
    const cleanedData = json_data.filter((organization) => organization.proponent && organization.proponent.name);
    setCleanedData(cleanedData);
  }, []);

  const projectStages = [
    'Design',
    'Validation',
    'Registration',
    'Implementation',
    'Monitoring',
    'Verification',
    'Issuance',
    'Sale&Retirement',
  ];

  const projectIcons = [
    faCircleCheck,
    faShield,
    faUser,
    faGear,
    faFileAlt,
    faCircleCheck,
    faZap,
    faDollarSign,
  ];

  const totalStages = projectStages.length;
  const visibleStages = 5;
  const [centeredStage, setCenteredStage] = useState(projectStages.indexOf(selectedStage));
  const [selectedStageName, setSelectedStageName] = useState(selectedStage);
  const [selectedStageContent, setSelectedStageContent] = useState('');
  const [expandedOrganization, setExpandedOrganization] = useState(null);

  useEffect(() => {
    fetchStageContent(selectedStageName);
  }, [selectedStageName]);

  const fetchStageContent = async (selectedStageName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/stage/stage/${selectedStageName}`);
      // handle the response here

      setSelectedStageContent(response.data.stage.Content);
    } catch (error) {
      console.error('Error fetching stage data:', error);
    }
  };

  const handleStageClick = (stage) => {
    const clickedStageName = projectStages[stage];
    setCenteredStage(stage);
    setSelectedStageName(clickedStageName);
  };

  const renderStages = () => {
    const stages = [];
    const start = centeredStage - Math.floor(visibleStages / 2);
    const end = start + visibleStages - 1;

    for (let i = start; i <= end; i++) {
      if (i >= 0 && i < totalStages) {
        stages.push(
          <div
            key={i}
            onClick={() => handleStageClick(i)}
            className='box-stage-button'
            style={{
              backgroundColor: centeredStage === i ? '#0087c3' : '#E0E3E7',
              color: centeredStage === i ? 'white' : '#333333',
            }}
          >
            <FontAwesomeIcon icon={projectIcons[i]} /> <span style={{ marginRight: '5px' }}></span>
            {projectStages[i]}
          </div>
        );
      }
    }

    return stages;
  };

  const handleOrganizationClick = (index) => {
    // Toggle the expanded organization index
    setExpandedOrganization((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <div className='box-container'>
        <div className='box-stage-buttons'>{renderStages()}</div>
        <p className='selected-stage-content'>{selectedStageContent}</p>
      </div>

      <div className='project_stakeholders' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <div className='project_stakeholders_header'>
          <h1 style={{ fontSize: '14px', color: '#50b848' }}>These are the TA providers that you will need at this stage</h1>

          <div className="custom-menu">

            {selectedStage === 'Registration' && (
              <>
            <div
              className={`menu-section ${selectedRole === 'community' ? 'active' : ''}`}
              style={{ backgroundColor: '#50b848' }}
              onClick={() => setSelectedRole('community')}
            >
              Community
            </div>
            <div
              className={`menu-section ${selectedRole === 'Developer' ? 'active' : ''}`}
              style={{ backgroundColor: '#0087c3' }}
              onClick={() => setSelectedRole('Developer')}
            >
              Developer
            </div>
            <div
              className={`menu-section ${selectedRole === 'Government' ? 'active' : ''}`}
              style={{ backgroundColor: '#f37021' }}
              onClick={() => setSelectedRole('Government')}
            >
              Government
            </div>
            </>
            )}

            {selectedStage === 'Issuance' && (
              <>
            <div
              className={`menu-section ${selectedRole === 'Carbon Registry' ? 'active' : ''}`}
              style={{ backgroundColor: '#50b848' }}
              onClick={() => setSelectedRole('Carbon Registry')}
            >
              Carbon Registry
            </div>
            <div
              className={`menu-section ${selectedRole === 'Developer' ? 'active' : ''}`}
              style={{ backgroundColor: '#0087c3' }}
              onClick={() => setSelectedRole('Developer')}
            >
              Developer
            </div>
          
            </>
            )}

            {selectedStage === 'Sale&Retirement' && (
              <>
            <div
              className={`menu-section ${selectedRole === 'Buyer' ? 'active' : ''}`}
              style={{ backgroundColor: '#50b848' }}
              onClick={() => setSelectedRole('Buyer')}
            >
              Buyer
            </div>
            <div
              className={`menu-section ${selectedRole === 'Investor' ? 'active' : ''}`}
              style={{ backgroundColor: '#0087c3' }}
              onClick={() => setSelectedRole('Investor')}
            >
              Investor
            </div>
            
            </>
            )}

            {(selectedStage === 'Design'|| selectedStage === 'Monitoring' || selectedStage === 'Implementation') && (
              <>
            <div
              className={`menu-section ${selectedRole === 'Developer' ? 'active' : ''}`}
              style={{ backgroundColor: '#50b848' }}
              onClick={() => setSelectedRole('Developer')}
            >
              Developer
            </div>
            <div
              className={`menu-section ${selectedRole === 'Investor' ? 'active' : ''}`}
              style={{ backgroundColor: '#0087c3' }}
              onClick={() => setSelectedRole('Investor')}
            >
              Investor
            </div>
            
            </>
            )}

            {(selectedStage === 'Validation' || selectedStage === 'Verification') && (
              <>
                <div
                  className={`menu-section ${selectedRole === 'VVB' ? 'active' : ''}`}
                  style={{ backgroundColor: '#50b848' }}
                  onClick={() => setSelectedRole('VVB')}
                >
                  VVB
                </div>
                <div
                  className={`menu-section ${selectedRole === 'Developer' ? 'active' : ''}`}
                  style={{ backgroundColor: '#0087c3' }}
                  onClick={() => setSelectedRole('Developer')}
                >
                  Developer
                </div>
              </>
            )}




            
          </div>
        </div>

        <div className="organization-container">
          {filteredData.length > 0 ? (
            filteredData.map((organization, index) => (
              <div key={index}>
                <div className="organization-item" onClick={() => handleOrganizationClick(index)}>
                  <p className="organization-details">{organization.other_entities.organization || 'N/A'}</p>
                  <p className="organization-location">{organization.location || 'N/A'}</p>
                </div>
                <ExpandMoreIcon onClick={() => handleOrganizationClick(index)} />

                {expandedOrganization === index && (
                  <div className="expanded-content">
                    {/* Show other_entities role, email, and contact */}
                    <h2 className="contact-info-heading">Contact Info</h2>
                    <div style={{ color: '#50b848' }}>
                      <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faUser} /> Role: {organization.other_entities.role}
                    </div>
                    <div style={{ color: '#50b848' }}>
                      <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faEnvelope} /> Email: {organization.other_entities.email}
                    </div>
                    <div style={{ color: '#50b848' }}>
                      <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faMapMarkerAlt} /> Contact: {organization.other_entities.contact}
                    </div>
                  </div>
                )}

                <hr style={{ border: '1px dashed #50b848' }} />
              </div>
            ))
          ) : (
            <p>No stakeholders in this stage.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoxComponent;
