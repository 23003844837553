import { useState, useContext, useEffect, createContext } from "react";
const AuthContext = createContext(null)

export const AuthProvider = ({children}) =>{

    const storedToken = localStorage.getItem('USER_SGP');
    const initialUser = storedToken ? JSON.parse(storedToken) : { /* default user object */ };

    const [user, setUser] = useState(initialUser);
    
    useEffect(() => {
        // Check if there is a token in localStorage
        const storedToken = localStorage.getItem('USER_SGP');
    
        if (storedToken) {
          // If a token is found, set the initial user state
          setUser(JSON.parse(storedToken));
        }
      }, []); 

    const login =  (user) => {
        localStorage.removeItem('USER_SGP');
        setUser(user);  
        localStorage.setItem('USER_SGP', JSON.stringify(user)); 
    }

    const logout = () =>{
        setUser(null)
        localStorage.removeItem('USER_SGP');
        localStorage.removeItem('selectedWorkgroup')
    }

    return (
            <AuthContext.Provider value = {{ user, login, logout}}>
                {children}
            </AuthContext.Provider>
    ) 

    
}

export const useAuth = () => useContext(AuthContext);