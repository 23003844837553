import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { WorkgroupContext } from '../../../context/workgroupContext';
import { useAuth } from '../../../context/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const WorkgroupDocuments = () => {
  const { defaultWorkgroup, deleteDocument, approveDocument } = useContext(WorkgroupContext);
  const { user } = useAuth();
  const userID = user.user._id;
  const userRole = user.user.UserRole;

  const handleDocumentDelete = async (documentId) =>{
    try{
      await deleteDocument(documentId);
      toast.success('Document Deleted Successfully', { containerId: 'container1' });
    }catch (error){

    }
  }



  const handleDocumentApprove = async (documentId) =>{
    try{
      await approveDocument(documentId)
      toast.success('Document Approved Successfully', { containerId: 'container2' });
    }catch (error){

    }
  }

  return (
    <>
    {defaultWorkgroup && (
      <div className="Workgroup_Container">
        <h2>Workgroup Documents</h2>
        {defaultWorkgroup.documentDetails && defaultWorkgroup.documentDetails.length > 0 ? (
          defaultWorkgroup.documentDetails.map((document, index) => (
            <div key={index} className="Workgroup_Item">
              <div className="Left_Section">
                <h3 className="Item_Name">{document.filename}</h3>
                {document.uploadDate && (
                  <p className="Uploaded_Date">
                    {new Date(document.uploadDate).toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                  </p>
                )}
              </div>
              <div className="Right_Section">
                <NavLink to={document.image} target="_blank">
                  <button>
                    <FontAwesomeIcon icon={faDownload} size="sm" style={{ color: 'white', marginRight: '5px' }} />
                    Download
                  </button>
                </NavLink>

                    {document.approved === false &&  userRole ==='admin' &&(
                     <FontAwesomeIcon icon={faThumbsUp}  className ='Right_Section_Icon_Approve' onClick ={()=> handleDocumentApprove(document._id)} title ='Approve Document?' />  
                    )}


                    {document.approved === false && (document.userID === userID || userRole ==='admin') &&(
                     <FontAwesomeIcon icon={faTrash}  className ='Right_Section_Icon' onClick ={()=> handleDocumentDelete(document._id)} title ='Delete Document'/>  
                    )}

                    
     
              </div>
            </div>
          ))
        ) : (
          <p style ={{fontSize:'12px', textAlign: 'center'}}>No documents available</p>
        )}
      </div>
    )}

    
    <ToastContainer
        position="top-right"
        autoClose={3000}
        containerId="container1" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        containerId="container2" // Specify a unique containerId
      />
    </>
  );
};

export default WorkgroupDocuments;

