import { Box, Typography } from "@mui/material";
import {  useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/SuperAdmin/Header";
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateDocumentStage = () => {
  const {user} = useAuth();
  const userId = user.user._id;
  //Need the workgroup name to add it as the context
  const {Sname: stageName} = useParams();
  const { id: stageId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const documentSchema = yup.object().shape({
    name: yup.string().required("Document Name is required"),
    file: yup.mixed().required("File is required").test ('fileType', 'Only PDF files are allowed', (value)=> value && value.type ==='application/pdf'),
  });
  const formik = useFormik({
    initialValues: {
      file: null,
      name: '',
    },
    validationSchema: documentSchema, 
    onSubmit: async (values) => {
      const formData = new FormData();
      const formattedName = values.name.replace(/\s+/g, '_');
      formData.append('file', values.file);
      formData.append('name', formattedName);
      formData.append('userID', userId);
      formData.append('context', stageName);
  
      try {
        setLoading(true);
        const uploadDocument = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/document/stage/${stageId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });


          const documentId = uploadDocument.data.savedDocument._id;
          try {

            try{
              const updatedDocument = await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/document/approveDocument/${documentId}`);
              
              const documentContext = updatedDocument.data.context;
              const documentFilename = updatedDocument.data.image;
          
              try {
                await axios.post('https://staging-bayesnet.bayesconsultants.com/api/acmi/upload', {
                  context: documentContext,
                  filename: documentFilename,
                });
              }catch(error){
                console.log(error.response.data)
              }
            }catch (error){
              console.log(error.response.data)
            }

           

           
          }catch (error){
            console.log (error.response.data)
          }
        



        toast.success('You have successfully added the document', {
          position: 'top-right',
          autoClose: 5000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

  

  
        navigate('/superadmin/stages');
      } catch (error) {
        console.log('The error is', error);
        setErrorMessage('There has been an error submitting the Document');
      }
    },
  });
  

  return (
    <Box m="20px">
      <Header title="CREATE DOCUMENT" subtitle="Add a New Document" />
    
  

     {errorMessage && (
       <Typography color="secondary" variant="body1" mt={2}>
         {errorMessage}
       </Typography>
     )}

     <form onSubmit={formik.handleSubmit} className="form-container">
         

         <div className="form-group">
           <input
             id="nameInput"
             value={formik.values.name}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             type="text"
             className={`did-floating-input ${formik.values.name ? 'focused' : ''} ${
                 formik.touched.name && formik.errors.name ? 'input-error' : ''
               }`}
               
             name="name"
           />

           <label className="did-floating-label">File Name</label>
           {formik.touched.name && formik.errors.name && (
             <div className="formik-error" style={{ color: '#d32f2f' }}>
               {formik.errors.name}
             </div>
           )}
         </div>


         <div className="form-group">
         
           <input
             id="contextInput"
             value={stageName}
             readOnly
             type="text"
             name="context"
             className="did-floating-input"
           />
         </div>

         <div className="file-upload">
         
             
             <input
               id="fileInput"
               onChange={(e) => formik.setFieldValue('file', e.currentTarget.files[0])}
               type="file"
               name="file"
               accept=".pdf"
             />
        
           {formik.touched.file && formik.errors.file && (
             <div className="formik-error" style={{ color: '#d32f2f' }}>
               {formik.errors.file}
             </div>
           )}
         </div>
         <div className="button-container">

          <button type="button" className="cancel-button" style ={{backgroundColor:'lightgray'}} onClick={() => navigate('/superadmin/stages')}>
            Cancel
          </button>
          <button type="submit" className="upload-button">
            {loading ? <div className='Signuploader' style ={{}}></div> : 'Upload File'}
          </button>
          </div>
          </form>


   
    </Box>
  );
};



export default CreateDocumentStage;
