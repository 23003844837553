import { Modal, Button, Box, Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

const UpdateWorkgroupForm = ({ isOpen, onClose, onUpdate, workgroup }) => {
  // Define state variables
  const [updatedData, setUpdatedData] = useState({
    WorkgroupName: '',
    About: '',
  });

  // Use state variables in your component
  const { WorkgroupName, About } = updatedData;

  // Define state updater functions
  const setUpdatedWorkgroupName = (value) => setUpdatedData((prevData) => ({ ...prevData, WorkgroupName: value }));
  const setUpdatedAbout = (value) => setUpdatedData((prevData) => ({ ...prevData, About: value }));

  // Update the form data when the workgroup prop changes
  useEffect(() => {
    if (workgroup) {
      setUpdatedData({
        WorkgroupName: workgroup.WorkgroupName || '',
        About: workgroup.About || '',
      });
    }
  }, [workgroup]);

  const handleUpdate = () => {
    // Implement the logic to update the workgroup with the updatedData
    // ...

    // Call onUpdate to inform the parent component about the update
    onUpdate({ ...updatedData, _id: workgroup._id });

    // Close the modal
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Edit Workgroup</Typography>

        <TextField
          label="Workgroup Name"
          variant="outlined"
          fullWidth
          value={WorkgroupName}
          onChange={(e) => setUpdatedWorkgroupName(e.target.value)}
          sx={{ mt: 2 }}
        />

        <TextField
          label="About"
          variant="outlined"
          fullWidth
          value={About}
          onChange={(e) => setUpdatedAbout(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ mt: 2 }}>
          Edit
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdateWorkgroupForm;
