import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/SuperAdmin/Header";
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateWorkgroup = () => {

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate()
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/`, values);

      toast.success('Workgroup created successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      resetForm();
      navigate('/superadmin/workgroup');
    } catch (error) {
      if (error.response) {
        setErrorMessage(`Server responded with a ${error.response.status} error`);
      } else if (error.request) {
        setErrorMessage('No response received from the server');
      } else {
        setErrorMessage('Unexpected error occurred');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE WORKGROUP" subtitle="Create a New Workgroup" />

      <Formik
        initialValues={{
            WorkgroupName: '', 
            About: '',
            
        }}
        validationSchema={workgroupSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>

          

            {errorMessage && (
              <Typography color="secondary" variant="body1" mt={2}>
                {errorMessage}
              </Typography>
            )}

            <Box
             
            >
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Workgroup Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.WorkgroupName}
                name="WorkgroupName"
                error={touched.WorkgroupName && !!errors.WorkgroupName}
                helperText={touched.WorkgroupName && errors.WorkgroupName}
                sx={{ mt: 2 }}
                />

                {/* About Input */}
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Workgroup Agenda"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.About}
                name="About"
                error={touched.About && !!errors.About}
                helperText={touched.About && errors.About}
                sx={{ mt: 2 }}
                />

                <label htmlFor="image-upload">
              <input
                style={{ display: 'none' }}
                id="image-upload"
                type="file"
                name="ImageURL"
                onChange={(event) => {
                  setFieldValue("ImageURL", event.currentTarget.files);
                }}
              />

              {/*  
              <Button component="span"  variant="outlined" sx={{ color: colors.primary[500] ,mt: 2 }}>
                Upload Image
              </Button>
              */}
            </label>


            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" sx={{ backgroundColor: colors.primary[500] }}  variant="contained" disabled={isSubmitting}>
                Create New Workgroup
              </Button>
            </Box>



            </Box>

          </form>
        )}
      </Formik>
    </Box>
  );
};

const workgroupSchema = yup.object().shape({
    WorkgroupName: yup.string().required("Workgroup Name is required"),
    About: yup.string().required("About is required"),
  
  });

export default CreateWorkgroup;