import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/SuperAdmin/Header';
import { Grid, Button, Card, CardContent, Typography, Box, Collapse, Modal, TextField, useTheme} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteModal from '../../components/SuperAdmin/DeleteModal';
import { Link } from 'react-router-dom';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from "../../theme";

const StageList = () => {
  

  const [stages, setStages] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [expandedMap, setExpandedMap] = useState({});
  const [selectedStageForUpdate, setSelectedStageForUpdate] = useState(null);
  const [updatedStageName, setUpdatedStageName] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  

  useEffect(() => {
    fetchStages();
  }, []);

  const fetchStages = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/stage`);
      setStages(res.data.stages);
      // Initialize expandedMap with false for each stage
      setExpandedMap(Object.fromEntries(res.data.stages.map((stage) => [stage._id, false])));
    } catch (error) {
      console.error('Error fetching stages:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const deleteStage = (id) => {
    setStageToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/stage/${stageToDelete}`);
      // Update state after successful deletion
      const newStages = stages.filter((stage) => stage._id !== stageToDelete);
      setStages(newStages);
      // Close the modal and reset stageToDelete
      setIsDeleteModalOpen(false);
      setStageToDelete(null);
      toast.success('Stage Deleted Successfully', { containerId: 'container2' });
    } catch (error) {
      console.error('Error deleting stage:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setStageToDelete(null);
  };

  const handleExpandClick = (id) => {
    setExpandedMap((prevExpandedMap) => ({
      ...prevExpandedMap,
      [id]: !prevExpandedMap[id],
    }));
  };

  const openUpdateModal = (stage) => {
    setSelectedStageForUpdate(stage);
    setUpdatedStageName(stage.StageName);
    setUpdatedContent(stage.Content);
  
    setIsUpdateModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/stage/${selectedStageForUpdate._id}`, {
        StageName: updatedStageName,
        Content: updatedContent,
       
      });
      // Fetch updated stages after saving
      fetchStages();
      // Close the modal
      setIsUpdateModalOpen(false);
      toast.success('Stage Updated Successfully', { containerId: 'container1' });
     
    } catch (error) {
      console.error('Error updating stage:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const handleUpdateModalClose = () => {
    setIsUpdateModalOpen(false);
    setSelectedStageForUpdate(null);
    setUpdatedStageName('');
    setUpdatedContent('');
   
  };

  

  return (
    <Box m="20px">
    <Header title="PROJECT STAGES" subtitle="" />
      <Grid container spacing={2}>
        {stages.map((stage) => (
          <Grid item key={stage._id} sx={{width :'32%'}}>
            <Card >
              <CardContent>
                <Typography variant="h4" component="div">
                  {stage.StageName}
                </Typography>
                <Box
                  sx={{
                    maxHeight: expandedMap[stage._id] ? 'none' : '100px',
                    overflow: 'hidden',
                  }}
                >
                  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: '2' }}>
                    {stage.Content}
                  </Typography>
                </Box>
                <Collapse in={expandedMap[stage._id]} timeout="auto" unmountOnExit>
                  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: '2' }}>
                    {stage.Content}
                  </Typography>

                 <Box  display="flex" justifyContent="space-evenly" p={2}>

                  <Button onClick={() => openUpdateModal(stage)} variant="contained" sx={{backgroundColor: colors.primary[500], border: "none"}} >
                    Edit
                  </Button>

                  <Button onClick={() => deleteStage(stage._id)} variant="contained" sx={{backgroundColor: colors.redAccent[500], border:"none"}}>
                    Delete
                  </Button>
                  </Box>
                </Collapse>
              </CardContent>
              <Box display="flex" justifyContent="space-evenly" p={2}>
                <Button
                  onClick={() => handleExpandClick(stage._id)}
                  variant="text"
                  sx={{color: colors.primary[500]}}
                  endIcon={<ExpandMoreIcon />}
                >
                  {expandedMap[stage._id] ? 'Collapse' : 'Expand'}
                </Button>

               
                  <Button
                    variant="outlined"
                    sx={{color: colors.primary[500]}}
                    component = {Link}
                    to = {`/superadmin/addStageDocument/${stage._id}/${stage.StageName}`}
                  >
                    Add Document
                  </Button>
               
               
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteConfirmation}
      />

      <Modal
        open={isUpdateModalOpen}
        onClose={handleUpdateModalClose}
        aria-labelledby="update-modal"
        aria-describedby="update-stage-details"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="update-modal" variant="h6" component="h2" gutterBottom>
            Update Stage
          </Typography>
          <TextField
            fullWidth
            label="Stage Name"
            value={updatedStageName}
            onChange={(e) => setUpdatedStageName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Content"
            multiline
            rows={4}
            value={updatedContent}
            onChange={(e) => setUpdatedContent(e.target.value)}
            margin="normal"
          />
         
          <Button onClick={handleUpdate} variant="contained" color="primary" sx={{ mt: 2 }}>
            Update
          </Button>
        </Box>
      </Modal>

     
      <ToastContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container1" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container2" // Specify a unique containerId
      />
      
    </Box>
  );
};

export default StageList;
