import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { RequireAuth } from "../../context/RequireAuth";
import Topbar from "../../scenes/global/Topbar";
import Sidebar from "../../scenes/global/Sidebar";
import { useState } from "react";
import '../../index.css';
import Dashboard from "../../scenes/dashboard";
import Team from "../../scenes/team";
import Workgroup from "../../scenes/workgroup";
import WorkgroupDetails from "../../scenes/workgroup/workgroupDetails";
import Project from "../../scenes/project";
import Originators from "../../scenes/originator";
import CreatePartner from "../../scenes/createPartner";
import CreateStage from "../../scenes/createStage";
import StageList from "../../scenes/createStage/stageList";
import Document from "../../scenes/document";
import CreateMember from "../../scenes/createMember";
import CreateDocument from "../../scenes/createDocument";
import CreateWorkgroup from "../../scenes/workgroup/createWorkgroup";
import CreateDocumentStage from "../../scenes/createDocumentStage";
import StageDocument from "../../scenes/createStage/stageDocument";
import AddAdmin from "../../scenes/workgroup/addAdmin";
import Stakeholders from "../../scenes/workgroup/stakeholders";


export default function SuperAdmin(){
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);

    JSON.parse(localStorage.getItem("USER_SGP"));
    
  
    return (

        <RequireAuth allowedRoles={['superAdmin']}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                   
            <div className = 'app'>
             <Sidebar isSidebar={isSidebar} />
             
            <main className='content'>
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                    <Route path="" element={<Dashboard />} />
                    <Route path="team" element={<Team />} />
                    <Route path='workgroup' element={<Workgroup />}/>
                    <Route path='workgroupDetails/:id/:name' element={<WorkgroupDetails />} />
                    <Route path='projects' element={<Project />}/>
                    <Route path='originators' element={<Originators/>}/> 
                    <Route path='createPartner' element={<CreatePartner />}/> 
                    <Route path='createStage' element={<CreateStage />}/> 
                    <Route path='stages' element={<StageList />}/> 
                    <Route path='documents' element={<Document />}/> 
                    <Route path="addWorkgroupMember/:id" element={<CreateMember />} />
                    <Route path="addWorkgroupDocument/:id/:Wname" element={<CreateDocument />} />
                    <Route path="addWorkgroupAdmin/:id" element={<AddAdmin />} />
                    <Route path="createWorkgroup" element={<CreateWorkgroup />} />
                    <Route path="addStageDocument/:id/:Sname" element={<CreateDocumentStage />} />
                    <Route path='stageDocuments' element={<StageDocument  />}/> 
                    <Route path='stakeholders' element={<Stakeholders />}/> 
                </Routes>
            </main>
            </div>
           
            </ThemeProvider>
            </ColorModeContext.Provider>
        </RequireAuth>
        
    )
}