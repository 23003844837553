import React, { useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalComponent from './Modal';
import DefaultWorkgroup from './DefaultWorkgroup';
import AllWorkgroups from './AllWorkgroups';
import WorkgroupDocuments from './WorkgroupDocument';
import { AdminContext } from '../../../context/admincontext';
import { WorkgroupContext } from '../../../context/workgroupContext';

import '../Originator.css';
import '../Admin.css';
import './Workgroup.css';

export default function WorkGroup() {
  const { user } = useAuth();
  const userRole = user.user.UserRole;
  const { disallowApproval } = useContext(AdminContext);
  const { defaultWorkgroup} = useContext(WorkgroupContext);

 


  return (
    <>
      {userRole !== 'partner' && (
        <>
          {defaultWorkgroup && (
            <div className = "Workgroup_AddDocument_Right">
            <button className="Workgroup_AddDocument_Button">
              <NavLink style={{ textDecoration: 'none' }} to={`/addDocument/pending/${defaultWorkgroup._id}/${defaultWorkgroup.WorkgroupName}`}>
                <FontAwesomeIcon icon={faPlusCircle} size="xl" style={{ color: 'white' }} />
                <span className="Workgroup_AddDocument_Link">Add Document</span>
              </NavLink>
            </button>
            </div>
          )}
        </>
      )}


        <DefaultWorkgroup/>
        <AllWorkgroups/>
        <WorkgroupDocuments/>
        

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container2" 
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container3" 
      />

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container4" 
      />
    </>
  );
}
