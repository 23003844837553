
import React, { useContext} from 'react';
import AIChatList from './AIChatList';
import AIDropdown from './AIDropdown';
import AIInput from './AIInput';
import AIPrompts from './AIPrompts';
import AIStages from './AIWorkgroups';
import { ChatContext } from '../../context/chatContext';
import './AI.css';

function AIWorkgroup() {

 
  const { stage, prompt, loading} = useContext(ChatContext);

  
    const generateText = () => {
      if (stage === '' || stage === null || stage === undefined) {
        return '';
      } 
      
      else if(stage === 'General'){
        return 'This module gives a detailed overview on Carbon Markets';
      }

      else if (stage === 'All'){
        return 'This module gives a detailed overview on all workgroups';
      }

      else {
        return `This module gives a detailed overview on the ${stage} workgroup`;
      }
    };

  return (
    <div>
    
      <div className="header">
          <h1>Ask Me Anything About Carbon Markets</h1>
          <p> 
            I know finding the right document or information can be a headache.
            But with my help, you'll be able to locate what you need in no time.
          </p>

      </div>
     
      <div className="Chat_Container">
        <AIDropdown />
      </div>

   
      <AIChatList />

      <div  style ={{padding:'0px', paddingLeft:'15px'}}className = 'Chat_Response'>
          <AIPrompts  />

          <div>
            
            {loading && (
              <>
                <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', minWidth: '82%', maxWidth: '82%', margin: '5px', paddingBlock:'3px' }}>
                  <p className="Prompt_Text">{prompt}</p>
                </div>
                <div className="loader" style={{marginTop: '0px', width: '22px', height: '22px'}}></div>
              </>
            )}
          </div>

          <form>
            <AIStages  />
            <p style ={{fontSize:'12px', paddingLeft:'8px', color:'#0087c3'}}>{generateText()}</p>
            <AIInput />
          </form>

      </div>
    </div>
   
  );
}

export default AIWorkgroup;