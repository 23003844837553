import './Originator.css'
import './OriginatorTop'
import Header from './Header'
import AI from '../AIChat/AI';
import OriginatorTop from './OriginatorTop';
import OriginatorMain from './OriginatorMain';
import { useState, useEffect, useContext } from 'react';
import {  Routes, Route } from "react-router-dom";
import { ExpansionContext } from '../../context/expansionContext';
import AICitations from '../AIChat/AICitations';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function Originator(){
 
    JSON.parse(localStorage.getItem("USER_SGP"));

    const {open, setButtonClicked, buttonClicked} = useContext(ExpansionContext);
    const [isExpanded, setIsExpanded] = useState(false);



    useEffect(() => {


      if (open) {
        if(buttonClicked) {
          setIsExpanded(true);
        } 

        if (!buttonClicked){
          setIsExpanded(false)
        }
       
      } 
      
    }, [open, buttonClicked]);
    

 

    const userRole = 'originator'; 
   

    return (
      <div>
            <div className="Originator">
            <Header userRole = {userRole}/>
            <OriginatorTop userRole = {userRole}/>

         

            <div className="Main">

                <div className={`Main-Left ${isExpanded ? 'shrink' : ''}`}>  
                    <OriginatorMain />  
                </div>

               

                <div className={`Main-Right ${isExpanded ? 'shrink' : ''}`} style ={{position: 'relative'}}>
                  <>
                      <div style={{position:'absolute', top:'50%', left:'0px'}}>
                      {buttonClicked && (
                        <ArrowForwardIosIcon onClick ={() => setButtonClicked(!buttonClicked)} style ={{fontSize: '15px', color: '#0087c3'}}/>
                      )}

                      {!buttonClicked && (
                        <ArrowBackIosIcon onClick ={() => setButtonClicked(!buttonClicked)} style ={{fontSize: '15px', color: '#0087c3'}}/>
                      )}
                    </div>
                    <Routes>
                        <Route index element={<AI userRole={userRole} />} /> {/* Render AI component when path is /developer */}
                        <Route path="citations/:promptId" element={<AICitations />} /> {/* Render AICitations component for /developer/citations/:promptId */}
                    </Routes>
                    </>
                </div>

            </div>

        </div> 
        
    </div>
    )
}