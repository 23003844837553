import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Originator.css';
import './OriginatorTop';
import Header from './Header';
import AI from '../AIChat/AI';
import OriginatorTop from './OriginatorTop';
import BoxComponent from './BoxComponent';
import { useAuth } from '../../context/auth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OriginatorProjectDetail() {
  JSON.parse(localStorage.getItem("USER_SGP"));
  const { user } = useAuth();
  const userRole = user.user.UserRole;


  
  
  const { projectID, projectName, stageName} = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [nextStage, setNextStage] = useState(stageName);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [stageDocuments, setStageDocuments] = useState(null);
  

    const stages = [
      'Design',
      'Validation',
      'Registration',
      'Implementation',
      'Monitoring',
      'Verification',
      'Issuance',
      'Sale&Retirement',
    ];
   // Mapping of stages to background colors
   const stageColors = {
    'Sale&Retirement': '#00CED1',
    'Issuance': '#005253',
    'Verification': 'orange',
    'Monitoring': '#F39C12',
    'Implementation': '#1ABC9C',
    'Registration': '#2980B9',
    'Validation': '#8E44AD',
    'Design': '#E74C3C',
  };
  const backgroundColor = stageColors[stageName] || 'gray';

  const containerStyle = {
    textAlign: 'center',
    width: '100%',
   
  };

  const stageBoxStyle = {
    backgroundColor,
  };

 



  useEffect(() => {
   
    fetchData();
  }, [projectID]); 


  const fetchData = async () => {
    try {
   
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/project/${projectID}`);
      setImageUrl(response.data.project.image);
    
    
      
    } catch (error) {
      console.error('Error fetching project image:', error);
    }
  };

  
  const handleCompleteStage = async () => {
    const currentIndex = stages.indexOf(stageName);

    if (currentIndex !== -1 && currentIndex + 1 < stages.length) {
      const nextStageName = stages[currentIndex + 1];
      setNextStage(nextStageName)
    }

    setShowCompleteModal(true);
    
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/stage/stage/${stageName}`);
    setStageDocuments(response.data.stage.Documents);
    
  }
  


const handleMoveToNextStage= async ()=>{
  try {
    // Make a request to your backend to update the stage
     await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/project/${projectID}`, {

    });

    setShowCompleteModal(false);

    
    toast.success(`You are now in the ${nextStage} stage.`, {
      position: 'top-right',
      autoClose: 2500, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    setErrorMessage('');
    navigate ('/developer')
   
  } catch (error) {
    console.error('Error completing stage:', error);
    // Handle error, show an error message, etc.

    setErrorMessage('There was an error completing the stage. Try again later');
  }

}
  

  const handleDeleteConfirmation = () => {
    setShowCompleteModal(false)
  };




  return (
    <div className="Originator">
      <Header userRole = {userRole} />
      <OriginatorTop userRole={userRole} />

      <div className="Main">
        <div className= "Main-Left" style ={{ paddingTop: '0px'}}>
          <div style={containerStyle}>

              <div className = 'ProjectDetails_Container'>
                <div 
                  style ={{display: 'flex', alignItems:'center',border:'1px solid #D35400', fontSize: '10px', minHeight:'15px', borderRadius:'10px', padding:'3px 8px', color:'#D35400', fontWeight:'600'}}>
                  Project Name
                </div>
                <h3 className = 'ProjectDetails_Container_Name' style = {{ color: '#0087c3',fontSize: '14px' }}>{projectName}</h3>
            
              </div>

            

            {errorMessage && (
              <div style={{ color: 'red', marginTop: '20px' }}>
                {errorMessage}
              </div>
            )}
        <div className = "projectDetails">
            <div style ={stageBoxStyle} className="stageBox">

            <div style ={{ width: '80px',display: 'flex', justifyContent:'center', alignItems:'center',border:'1px solid white', fontSize: '11px', height:'15px', borderRadius:'12px', padding:'4px 9px', color:'white', fontWeight:'500'}}>Stage</div>
              <h4> 
                <span
                style={{
                lineHeight: '1.6',
                fontSize: '16px',
                padding: '0px',
                color: 'white',
                fontWeight: '600'
              }}
                > 
                {stageName === 'Sale&Retirement' ? 'Sales and Retirement' : `Project ${stageName}`}
                
                </span>
                
                </h4>

                {stageName !== 'Sale&Retirement' && (
                  <>
                  <button className = 'button-details' onClick={handleCompleteStage}>
                 
                    <CheckCircleOutlineIcon style={{ marginRight: '5px', fontSize:'16px' }} />
                    Complete this stage
                  </button>
                  </>
                )}

            </div>

            <div className = "imageProjectDetails">
              {imageUrl && <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={imageUrl} alt="Project" />}
            </div>

              
            </div>

            <BoxComponent selectedStage={stageName}  />

            {showCompleteModal && (
                <div className="modal" style ={{zIndex:'999999999', backgroundColor: 'rgba(0, 0, 0, 0.52)' }}>
                <div className="modal-content">
                  <h4 style ={{textAlign: 'left', fontFamily:'Poppins'}}>Complete Project {stageName} Stage</h4>
                    <p style = {{color: '#333333', fontSize:'13px', lineHeight:'1.4', fontFamily:'Poppins'}}>Please ensure that you have the necessary documents so that you can successfully move to the next stage</p>
                    {stageDocuments && (
                      <ol>
                        {stageDocuments.map((document, index) => (
                          <li key={index} style ={{color: '#333333', fontSize:'13px', lineHeight:'2'}}>
                            <p> <strong>{document.DocumentName}</strong>- {document.Description}</p>
                          </li>
                        ))}
                      </ol>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    
                    <button className ='button-style Cancel_Stage'
                  

                  onClick = {handleDeleteConfirmation}
                    
                    >Cancel</button> <span style ={{color:"transparent"}}> Sp</span>
                    <button 
                    className ='button-style Next_Stage'
                    
                
                  onClick={handleMoveToNextStage}
                >Move to {nextStage}</button>
                </div>
                </div>
                </div>

                )}

          </div>
     
        </div>

        
        <div className='Main-Right'>
          <AI />
        </div>
      </div>
    </div>
  );
}