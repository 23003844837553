import { Modal, Button, Box, Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

const UpdatePartnerForm = ({ isOpen, onClose, onUpdate, partner }) => {
  // Define state variables
  const [updatedData, setUpdatedData] = useState({
    Name: '',
    Number: '',
    Email: '',
  });

  // Use state variables in your component
  const { Name, Number, Email } = updatedData;

  // Define state updater functions
  const setUpdatedName = (value) => setUpdatedData((prevData) => ({ ...prevData, Name: value }));
  const setUpdatedNumber = (value) => setUpdatedData((prevData) => ({ ...prevData, Number: value }));
  const setUpdatedEmail = (value) => setUpdatedData((prevData) => ({ ...prevData, Email: value }));

  // Update the form data when the partner prop changes
  useEffect(() => {
    if (partner) {
      setUpdatedData({
        Name: partner.Name || '',
        Number: partner.Number || '',
        Email: partner.Email || '',
      });
    }
  }, [partner]);

  const handleUpdate = () => {
    // Implement the logic to update the partner with the updatedData
    // ...

    // Call onUpdate to inform the parent component about the update
    onUpdate({ ...updatedData, _id: partner._id });

    // Close the modal
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6">Edit Partner</Typography>

        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={Name}
          onChange={(e) => setUpdatedName(e.target.value)}
          sx={{ mt: 2 }}
        />

        <TextField
          label="Number"
          variant="outlined"
          fullWidth
          value={Number}
          onChange={(e) => setUpdatedNumber(e.target.value)}
          sx={{ mt: 2 }}
        />

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={Email}
          onChange={(e) => setUpdatedEmail(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Button variant="contained" color="primary" onClick={handleUpdate} sx={{ mt: 2 }}>
          Edit
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdatePartnerForm;