import React, { useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { ChatContext } from '../../context/chatContext';

function AIInput() {


  const { prompt, stage, setPrompt, addPrompt, loading, currentChatId, createChat} = useContext(ChatContext);

  
  const handlePromptChange = (e) =>{
  setPrompt (e.target.value)
  }

  const handleFormSubmit = async(e) =>{
  e.preventDefault();

  if (currentChatId === '' || currentChatId === undefined || !currentChatId){
    await createChat();
  }
  await addPrompt();   
  }

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      if (currentChatId === '' || currentChatId === undefined || !currentChatId){
        await createChat();
      }
      await addPrompt(); // Call your addPrompt function
    }
  }
  

  return (
 
      <div style={{ position: 'relative', display: 'flex', borderRadius: '8px', overflow: 'hidden', paddingRight:'21px' }}>
 
      <div className="form-group" style={{ flex: '85%', top: '10px'}}>
        <input
          className={`did-floating-input`}
          type="text"
          value={prompt}
          onChange={handlePromptChange}
          onKeyPress={handleKeyPress}
          style={{ height:'45px',padding: '8px', color:'black', backgroundColor:'#F1F4F8', borderColor: stage ? '#F87524' : '#0087c3', borderRadius: '8px 0 0 8px', flex: 1 }}
        />
        <label className="did-floating-label" style={{ backgroundColor:'#F1F4F8' }}>Type your question ...</label>
      </div>
      <button
      type="submit"
        className="buttonInput"
        style={{flex:'15%', height:'45px', marginTop:'10px', backgroundColor: stage? '#F87524':'#0087c3', color: 'white', borderRadius: '0 8px 8px 0', border: 'none', cursor: 'pointer', padding: '8px' }}
        onClick ={handleFormSubmit}
      >
      
      
      {(loading === true) ? (
          <div className="loader" style={{marginTop: '0px', width: '22px', height: '22px'}}></div>
        ) : (
          <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginRight: '5px' }} />
        )}
        
        
      </button>
      
    </div>

  );
}

export default AIInput;

