import React from 'react';

const Modal = ({ isOpen, title, message, onClose, onConfirm }) => {
  return (
    <div className={`modal ${isOpen ? 'is-open' : ''}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
      <div className="modal-content" style={{ backgroundColor: '#fff', width: '400px', margin: '100px auto', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', position: 'relative' }}>
        <span className="close" onClick={onClose} style={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer', fontSize: '20px' }}>&times;</span>
        <h2 style={{fontSize:'18px'}}>{title}</h2>
        <p style={{fontSize:'14px'}}>{message}</p>
        <div className="modal-buttons">
          <button onClick={onClose} style={{ padding: '8px 16px', backgroundColor: '#dc3545', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Cancel</button>
          <button onClick={onConfirm} style={{ marginRight: '10px', padding: '8px 16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Confirm</button>
         
        </div>
      </div>
    </div>
  );
}

export default Modal;
