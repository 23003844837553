import React, { useContext, useState, useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatContext } from '../../context/chatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';

function AIChatList() {
  const { chats, setStage, currentChatId, setCurrentChatId, deleteChat, setProjectId } = useContext(ChatContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const [clickedChatId, setClickedChatId] = useState(null);
  const chatRefs = useRef({});

  useEffect(() => {
    // Scroll to the chat whose ID is currentChatId when it changes
    if (currentChatId && chatRefs.current[currentChatId]) {
      chatRefs.current[currentChatId].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentChatId]);

  const handleChatClick = (projectStage, chatId, projectId) => {
    setCurrentChatId(chatId);
    setStage(projectStage || ''); 
    setClickedChatId(chatId); 
    setProjectId(projectId || '');
  };

  const handleDeleteClick = (chatId) => {
    setChatToDelete(chatId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await deleteChat(chatToDelete);
    setShowDeleteModal(false);
    toast.success('Chat successfully deleted', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setChatToDelete(null);
  };

  return (
    <div className='Chats'>
      <div style={{ marginBottom: '5px' }}>
        {chats ? (
          chats.map((chat) => (
            <div className={`Chat`} style={{ backgroundColor: currentChatId === chat._id ? '#CCE5F0' : 'white' }} key={chat._id} onClick={() => handleChatClick(chat.projectStage, chat._id, chat.projectId )}
            ref={currentChatId === chat._id ? (element) => { chatRefs.current[chat._id] = element; } : null}
               >
              <div className='Chat_Text' style={{ flex: '5' }}>
                <span className='Chat_Time'>
                  {new Date(chat.dateAdded).toLocaleString('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </span>
                <br />
                {chat.chatName}
              </div>
              {clickedChatId === chat._id && ( // Only show delete icon if the current chat ID matches the clicked chat ID
                <button style ={{border:'none', backgroundColor:'#CCE5F0'}} onClick={() => handleDeleteClick(chat.projectStage, chat._id)}>
                  <FontAwesomeIcon icon={faTrash} style={{ color: 'red' }} />
                </button>
              )}
            </div>
          ))
        ) : (
          <div>No chats available</div>
        )}
      </div>

      
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      {showDeleteModal && (
        <Modal
          title='Confirm Delete'
          message='Are you sure you want to delete this chat?'
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
        />
      )}
    </div>
  );
}

export default AIChatList;
