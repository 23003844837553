// DeleteModal.js

import React from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';

const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal open={isOpen} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ height:'23vh', bgcolor: 'white', pt: 2, px:3,  borderRadius: '12px', textAlign: 'center', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'space-around' }}>
        <Typography variant="h5" sx={{fontSize :'14px'}}>
          Are you sure you want to delete this item?
        </Typography>

        <Box sx={{alignSelf:'flex-end', display:'flex', gap:'10px',  justifyContent:'flex-end' }}>
      
          <Button onClick={onClose} variant="contained" 
            sx={{ 
              borderRadius:'10px',
              backgroundColor:'#555555', 
              color:'white',
              '&:hover':{
                backgroundColor:'white',
                color:'#555555'
              }
              
              }}>
            Cancel
          </Button>

          <Button onClick={onConfirm} variant="contained" sx={{backgroundColor:'#8B0000',  borderRadius:'10px', }}>
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
