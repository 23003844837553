import {useState, useEffect, createContext} from 'react';

export const ExpansionContext = createContext();

export const ExpansionContextProvider = ({children}) =>{

      //check if window.innerWidth is greater than 720 px 
      const [open, setOpen] = useState(false);

      useEffect(() =>{
        if (window.innerWidth > 768){
            setOpen(true);
        }
      }, [])

     

    JSON.parse(localStorage.getItem("USER_SGP"));
     
    
      //check if the expansion button is clicked
      const [buttonClicked, setButtonClicked] = useState(false)
      

    return (
        <ExpansionContext.Provider value ={{open, buttonClicked, setButtonClicked}}>
          {children}
        </ExpansionContext.Provider>
    )
}

