import { useContext } from 'react';
import { ChatContext } from '../../../context/chatContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { WorkgroupContext } from '../../../context/workgroupContext';


const AllWorkgroups = () => {
  const { workgroups, setDefaultWorkgroupID} = useContext(WorkgroupContext);

  const { projectId, handleProjectClickAI} = useContext(ChatContext);

  const handleWorkgroupClick = (workgroup) => {
 
    const wName = workgroup.WorkgroupName;
    const abbreviationMatches = wName.match(/\(([^)]+)\)/g);
    const abbreviations = abbreviationMatches ? abbreviationMatches.map(match => match.slice(1, -1)) : [];
    const abbreviation = abbreviations.join(', ');

    const wID = workgroup._id;
    setDefaultWorkgroupID(wID)
    handleProjectClickAI(wID, abbreviation)
  }

  return (
    <div className="Agendas">

      {workgroups && workgroups.map((workgroup) => (
        <div
          key={workgroup._id}
          className={`Agenda ${projectId === workgroup._id ? 'select' : ''}`}
          style={{ justifyContent: 'space-between', alignItems:'left' }}
          onClick={() => handleWorkgroupClick(workgroup)}
        >
          <button>{workgroup.memberCount} Members</button>
          <p style={{ color: '#333333', fontWeight: '600', textAlign: 'left', fontSize: '12px' }}>{workgroup.WorkgroupName}</p>
          <ArrowForwardIosIcon style={{ marginTop: '6px', color: '#f37326' , fontSize:'13px', border:'2px solid #f37326', borderRadius:'50%',padding:'5px'}} />
        </div>
      ))}
    </div>
  );
};

export default AllWorkgroups;
