import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ChatContext } from '../../context/chatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faCheck} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';

function AIPrompts() {
  const { prompts } = useContext(ChatContext);
  /*const lastPromptRef = useRef(null);

  useEffect(() => {
    // Scroll to the last prompt response when component mounts or prompts change
    if (lastPromptRef.current) {
      lastPromptRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [prompts]);

  ref={index === prompts.length - 1 ? lastPromptRef : null}*/

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (message) => {
      navigator.clipboard.writeText(message);
      setCopied(true);
      setTimeout(() => {
          setCopied(false);
      }, 2000); // Reset the copied state after 2 seconds
  };



  return (
    <div className='allPromptsContainer'>
      {prompts && prompts.length > 0 ? (
        <div>
          {prompts.map((prompt, index) => (
            <div key={prompt._id} style={{ marginBottom: '10px', padding: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ backgroundColor: 'white', height: 'auto', borderRadius: '10px', minWidth: '82%', maxWidth: '82%', margin: '5px' }}>
                  <p className="Prompt_Text">{prompt.promptText}</p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '10px' }}>
                <div style={{ backgroundColor: 'white', padding: '8px', borderRadius: '10px', minWidth: '90%', maxWidth: '90%', margin: '5px', border: '2px solid #0089c38a' }}>
                  <p className="Prompt_Response" ><ReactMarkdown>{prompt.response}</ReactMarkdown></p>
                  <div className='Citations_div'>
                    <NavLink to ={`./citations/${prompt._id}`} className ='Citations'>Citations</NavLink>
                    <button className = 'Prompt_Response_Copy' style ={{border: 'none', backgroundColor:'inherit'}} onClick={() => copyToClipboard(prompt.response)}>
                        <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                    </button> 
                  </div>
                </div>
              </div>

              
            </div>
          ))}
        </div>
      ) : (
        <p style={{ fontSize: '12px', color: 'red', paddingBlock: '20px', textAlign: 'center' }}>No questions yet. Start asking me questions about workgroups or carbon markets.</p>
      )}
    </div>
  );
}

export default AIPrompts;
