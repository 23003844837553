import React, { useContext, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ChatContext } from '../../context/chatContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AIStages() {

  const { stage, setStage, resetAIChat} = useContext(ChatContext);
  const isStageSelected = stage !== '';

  const selectRef = useRef(null);

  /*useEffect(() => {
    if (selectRef.current) {
      selectRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [prompts]);*/

  const handleStageChange = (e) =>{
    setStage(e.target.value)
  }

  const handleReset = (e) =>{
    e.preventDefault(); 
    setStage('')
    toast.success("Chat Reset: Please select a new workgroup to start a new chat.", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,

    });
    resetAIChat();
  }


  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0', paddingRight:'21px' }}>

      <select
        ref={selectRef}
        value={stage}
        onChange={handleStageChange}
        style={{
          padding: '12px',
          border: '2px solid lightgray',
          fontFamily:'Poppins',
          fontSize:'12px',
          fontWeight:'600',
          color:'#333333',
          height: '45px',
          width: isStageSelected ? '80%' : '100%', 
          borderRadius: '8px',
           
        }}
      >
    
        <option value="">Please Select Workgroup</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="BIODIV">Biodiversity/nature credit</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="PD-E">Project Development Enablement</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="CCMAPEE">Country Carbon Market Activation Plans Enabling Environment</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="PD-ICF">Project Development Investment and carbon Financing</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="MRVIA">MRV, Integrity and Advocacy</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>
        <option value="All">All</option>
        <option value="" style={{ fontSize: '5px' }} disabled>&nbsp;</option>

        <option value="General" title="Ask me general questions on carbon markets" style={{ cursor: 'help', fontWeight:'bolder'}}>General</option>
        <option value="" style={{fontSize: '5px'}} disabled>&nbsp;</option>


      </select>

      {isStageSelected && (
        <button
          onClick={handleReset}
          type ="button"
          style={{
            height: '45px',
            backgroundColor: '#F87524',
            color: 'white',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            width: '19%',
            marginLeft: '3px',
            fontWeight:'600',
            fontSize:'12px',
            paddingInline:'23px',
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            
          }}
        >
        <FontAwesomeIcon icon={faTimes}  style={{ marginRight: '5px' }}  /> Reset
        </button>
      )}
      <ToastContainer position="top-right"  />

    </div>
  );
}

export default AIStages;
