import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import './SignIn.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const SignInSchema = Yup.object().shape({
  Email: Yup.string().email('Invalid email address').required('Email is required'),
  Password: Yup.string().required('Password is required'),
});

const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      Email: '',
      Password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      try {
        const baseURL = process.env.REACT_APP_BASE_URL;
      
        const response = await axios.post(`${baseURL}/v1/auth/login`, values, {withCredentials: true});
        const userRole = response.data.user.UserRole;
        const userName = response.data.user.Name;
   
        auth.login({ user: response.data.user, role: userRole, name: userName});
   

        // Handle role-based navigation
        if (userRole === 'superAdmin')
        {
          navigate('/superadmin', {replace: true});
        }
        else if (userRole === 'originator'){     
            navigate('/developer',{replace: true}); 
        }else if (userRole === 'member'){
            navigate('/member', {replace: true});
        }else if (userRole === 'admin'){
            navigate('/admin', {replace: true});
        }else if (userRole === 'partner'){
            navigate('/partner', {replace: true});
        }   
         else{
           setErrorMessage("Invalid username or password");
        }
    resetForm();

      } catch (error) {
        
        if (error.response.data.message === 'Account not verified. Please verify your email.') {
          setErrorMessage('Account not verified. Please verify your email.');
        }else if (error.response){
          setErrorMessage('Invalid username or Password')
        }else if (error.request){
          setErrorMessage('No response received from the server')
        }else {
          setErrorMessage('Unexpected message error occurred');
        }
      } finally{
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="SignIn">
      <div className="SignIn-Left">
        <img src="/ACMI_PIC.png" alt="Background" className="background" />
      </div>
      <div className="SignIn-Main-Container">
        <div className="SignIn_Container">
        <img src="/acmi-logo-color.png" alt="Logo" className="logo" />
        <h3 className="welcome">Welcome Back!</h3>
        <p className="welcome-p">Let's build something great together. Please sign in to continue. </p>

        {/* Display server error message */}
        {errorMessage && <p style ={{color: 'red', textAlign: 'center', fontSize:'12px'}}>{errorMessage}</p>}
    
        
        <form onSubmit={formik.handleSubmit}>
          <div key="Email" >
            <p className="formTitle-p">Email</p>
            <div className="form-group" >
              <input
                className={`did-floating-input ${formik.values.Email ? 'focused' : ''} ${
                  formik.touched.Email && formik.errors.Email ? 'input-error' : ''
                }`}
                type="email"
                placeholder=" "
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
                name="Email"
              />
              <label className="did-floating-label">Your Email</label>
              <div style={{ height: '0px', paddingTop:'0', marginBlock:'0px'}}></div>
              {formik.touched.Email && formik.errors.Email && (
                <div className="formik-error" style={{ color: '#d32f2f'}}>
                  {formik.errors.Email}
                </div>
              )}
            </div>
          </div>

          <div key="Password">
            <p className="formTitle-p">Password</p>
            <div className="form-group">
              <input
                className={`did-floating-input ${formik.values.Password ? 'focused' : ''} ${
                  formik.touched.Password && formik.errors.Password ? 'input-error' : ''
                }`}
                type={showPassword ? 'text' : 'password'}
                placeholder=" "
                autocomplete="off"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Password}
                name="Password"
              />
              <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              <label className="did-floating-label">Your Password</label>
              <div style={{ height: '0px', paddingTop:'0', marginBlock:'0px'}}></div>
              {formik.touched.Password && formik.errors.Password && (
                <div className="formik-error" style={{ color: '#d32f2f' }}>
                  {formik.errors.Password}
                </div>
              )}
            </div>
          </div>

          <button className="signin-button" type="submit">
            Sign In
          </button>
        </form>

      
        <NavLink className='Terms-p' to ='/terms-and-conditions'>
            By signing up, you agree to our <span>Terms and Conditions</span>
        </NavLink>
       
        <div className ='sign_footer'>
          <p>
            <NavLink to="/forget" className="nav-link" activeClassName="active-nav-link">
              Forgot Password?
            </NavLink>
          </p>

          <p>
        
            <NavLink to="/signUp" className="nav-link" activeClassName="active-nav-link">
              Sign Up
            </NavLink>
          </p>
        </div>
      
      
      </div>
      </div>

      <ToastContainer containerId="success"/>
      <ToastContainer containerId="success-reset"/>
      <ToastContainer containerId="success-forget"/>
      <ToastContainer containerId="success-verify"/>
      <ToastContainer containerId=" account-verification-success"/>
      <ToastContainer containerId=" already-verified-token"/>
      <ToastContainer containerId="success-request-new-token"/>
     
        
    </div>
  );
};

export default SignIn;