import './Verification.css';
import axios from 'axios';
import {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerificationSuccess = () => {

    const navigate = useNavigate();
    const { token } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);

    const verifyUser = useCallback(async () => {
        try {
          await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/auth/verify/${token}`);
          toast.success('You have successfully verified your account. LOG IN', {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            containerId: 'account-verification-success',
          });
    
          navigate('/');
        } catch (error) {
          const { msg } = error.response.data;
    
          if (msg === 'Verification token has expired') {
            setErrorMessage('Verification token has expired');
            toast.error('Your verification Token has Expired. SIGN UP AGAIN', {
              position: 'top-right',
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              containerId: 'verify-token-expiry',
            });
    
            navigate('/signUp');
          } else if (msg === 'User is already verified') {
            setErrorMessage('User is already verified');
            toast.success('You are already verified. LOGIN', {
              position: 'top-right',
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              containerId: 'already-verified-token',
            });
    
            navigate('/');
          } else {
            setErrorMessage('An error occurred');
          }
        }
      }, [token, navigate]);
    
      useEffect(() => {
        verifyUser();
      }, [verifyUser]);
    return (
        <div className="verification-container">
           
            {errorMessage && (
                <p className = 'error-name'>{errorMessage}</p>
            )}
                
        </div>
    );
};

export default VerificationSuccess;