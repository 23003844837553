

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Unauthorized.css'; // Import the CSS file

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <h2 className="unauthorized-heading">Unauthorized Access</h2>
      <p className="unauthorized-message">You do not have the necessary permissions to access this page.</p>
      
      <NavLink to="/" className="unauthorized-link">
        Go Back to Login Page
      </NavLink>
    </div>
  );
};

export default Unauthorized;

