import React, { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import './Quiz.css';

const questions = [
  {
    text: 'Do you have a project idea note?',
    explain: 'A PIN is a document that outlines the basic concept and objectives of a proposed carbon credit project.',
    yesHandler: 'Design',
    noHandler: null,
  },
  {
    text: 'Have you developed a PDD?',
    explain: 'A PDD includes information on project activities, methodologies for measuring and monitoring carbon emissions reductions or removals, baseline emissions data, and project boundaries.',
    yesHandler: 'Validation',
    noHandler: 'Design',
  },
  {
    text: 'Has your PDD been validated by a VVB?',
    explain: 'Validation, Verification, and Certification (VVB) are processes involved in assessing and confirming the eligibility of a carbon credit project.',
    yesHandler: 'Registration',
    noHandler: 'Validation',
  },
  {
    text: 'Have you registered with any registry?',
    explain: 'Carbon credit projects need to be registered by a recognized registry to facilitate the issuance, trading, and tracking of carbon credits.',
    yesHandler: 'Implementation',
    noHandler: 'Registration',
  },
  {
    text: 'Have you began the project implementation?',
    explain: 'It includes project setup, mobilization, stakeholder engagement, baseline assesment, and data collection.',
    yesHandler: 'Monitoring',
    noHandler: 'Implementation',
  },
  {
    text: 'Have you completed monitoring your project for the given crediting cycle?',
    explain: 'Monitoring is considered complete when emissions reductions or removals are quantified and verified.',
    yesHandler: 'Verification',
    noHandler: 'Monitoring',
  },
  {
    text: 'Do you have an MRV Report and has it been verified by a VVB?',
    explain: 'An MRV report provides detailed information on the measurement, reporting, and verification of carbon emissions reductions or removals achieved by a carbon credit project. ',
    yesHandler: 'Issuance',
    noHandler: 'Verification',
  },
  {
    text: 'Do you have credit issued on your project?',
    explain: 'credits represent the verified emissions reductions or removals achieved by the project during the crediting cycle.',
    yesHandler: 'Sale&Retirement',
    noHandler: 'Issuance',
  },
];


const Quiz = ({ onStageSelection }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [noStageSelected, setNoStageSelected] = useState(false);
    const [ideaNoteError, setIdeaNoteError] = useState(false);
    const [showSelectedInfo, setShowSelectedInfo] = useState(false);

    
  
    const handleYes = () => {
        
        const currentQuestion = questions[currentQuestionIndex];
     
        if (currentQuestionIndex === questions.length - 1) {
          // Last question, set the stage to Sale&Retirement
       
          onStageSelection('Sale&Retirement');

            // Show the "selected-info" section after a delay
            setTimeout(() => {
                setShowSelectedInfo(true);
            }, 1000); 
        } else {
          // Not the last question, proceed with the usual handling
          onStageSelection(currentQuestion.yesHandler);
          if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
          }
        }
      
        setNoStageSelected(false);
        setIdeaNoteError(false);
      };
      
  
    const handleNo = () => {
      const currentQuestion = questions[currentQuestionIndex];
      if (currentQuestionIndex === 0) {
        // Special case for the first question (project idea note)
        setIdeaNoteError(true);
        setNoStageSelected(false);
      } else {
        onStageSelection(currentQuestion.noHandler);
        setNoStageSelected(true);
      }
    };
  
    const handleReset = () => {
      onStageSelection(null);
      setCurrentQuestionIndex(0);
      setNoStageSelected(false);
      setIdeaNoteError(false);
    };
  
    return (
      <div>
        <div className='Quiz' style={{ padding: '0px', borderRadius: '16px', fontSize: '12px' }}>
          {currentQuestionIndex < questions.length && (
            <div>
              <p>{questions[currentQuestionIndex].text}</p>
              <p style ={{color: 'gray', paddingBlock:'0px'}}>{questions[currentQuestionIndex].explain}</p>
              <button type='button' onClick={handleYes}><span className='buttonSpan'>&#10004;</span>  Yes</button>
              <button type='button' onClick={handleNo}><span className='buttonSpan'>&#10006; </span> No</button>
            </div>
          )}
  
          {showSelectedInfo && currentQuestionIndex === questions.length - 1 && (
          <div className="selected-info">
            <>
              <p className="selected-text">You have Selected Sales and Retirement Stage</p>
              <button style={{ marginTop: '10px', backgroundColor: '#14D2D5', border: 'none', color: 'white', fontWeight: '600' }} className="reset-button" onClick={handleReset}>
                <ErrorIcon style={{ marginRight: '5px' }} /> Not Sure? Reset
              </button>
            </>
          </div>
        )}

  
          {noStageSelected === true && (
            <div className="selected-info">
              <p className="selected-text-error">{`You are in the ${questions[currentQuestionIndex].noHandler} stage`}</p>
              <button style={{ marginTop: '10px', backgroundColor: '#14D2D5', border: 'none', color: 'white', fontWeight: '600' }} className="reset-button" onClick={handleReset}>
                <ErrorIcon style={{ marginRight: '5px' }} /> Not Sure? Reset
              </button>
            </div>
          )}
  
          {ideaNoteError === true && (
            <div className="selected-info">
              <p className="selected-text-error">You cannot have a project without an idea note.</p>
              <button style={{ marginTop: '10px', backgroundColor: '#14D2D5', border: 'none', color: 'white', fontWeight: '600' }} className="reset-button" onClick={handleReset}>
                <ErrorIcon style={{ marginRight: '5px' }} /> Refresh
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
  
  export default Quiz;
  