import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GroupIcon from '@mui/icons-material/Group';
import Header from "../../components/SuperAdmin/Header";
import StatBox from "../../components/SuperAdmin/StatBox"


const Dashboard = () => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [userCounts, setUserCounts] = useState({
    partnerCount: 0,
    originatorCount: 0,
    memberCount: 0,
    adminCount:0,
  });

  const [projectList, setProjects] = useState([]);

  useEffect(() => {
    // Fetch counts when the component mounts
    getCounts();
    fetchProjects();
  }, []);

  const getCounts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/getCount`); 
      const counts = response.data;
      setUserCounts(counts);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  const fetchProjects = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/project`); 
    setProjects(res.data.projects);
  };

  const displayedProjects = projectList.slice(0, 7);

  const columns = [
    {
        field: 'sequence',
        headerName: '#',
        flex: 0.5,
        renderCell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            {row.sequence}
          </div>
        ),
      },
   
    { field: 'Project Name', headerName: 'ProjectName', flex: 1, cellClassName: 'name-column--cell',sortable: true  },
    { field: 'Project Stage', headerName: 'Stage', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'Project Type', headerName: 'ProjectType', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'Project Location', headerName: 'Location', flex: 1, cellClassName: 'name-column--cell' },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: '16px' }}
        >
          <StatBox
            title="Developer Count"
            subtitle={userCounts.originatorCount}
            progress="0.50"
        
            icon={
              <PersonAddIcon
                sx={{ color: "white", fontSize: "" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.greenAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: '16px' }}
        >
          <StatBox
            title="Member Count"
            subtitle={userCounts.memberCount}
            icon={
              <GroupIcon
                sx={{ color: "white", fontSize: "" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: '16px' }}
        >
          <StatBox
            title="Admin Count"
            subtitle={userCounts.adminCount}
            icon={
              <SupervisorAccountIcon
                sx={{ color: "white", fontSize: "" }}
              />
            }
          />
        </Box>

        <Box
          gridColumn="span 3"
          backgroundColor={colors.orangeAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderRadius: '16px' }}
        >
          <StatBox
            title="Partner Count"
            subtitle={userCounts.partnerCount}
            icon={
              <BusinessCenterIcon 
                sx={{ color: "white", fontSize: "" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
          
            <Box mt="25px" p="0 30px" sx={{width:'100%'}}>
              <Typography variant="h5" fontWeight="600" color={colors.primary[500]}>
                Projects
              </Typography >
              <br />

              <DataGrid
                checkboxSelection
                rows={displayedProjects.map((project, index) => ({
                  id: index,
                  sequence: index + 1,
                  'Project Name': project.ProjectName,
                  'Project Stage': project.Stage,
                  'Project Type': project.ProjectType,
                  'Project Location': project.Location
                }))}
                columns={columns}
                getRowId={(row) => row.id}
              />

              
            </Box>

          
          </Box>
         
        </Box>
        

        {/* ROW 3 
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
         
      </Box>*/}
    </Box>
    </Box>
  );
};

export default Dashboard;