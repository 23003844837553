import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { Box, useTheme, Button} from "@mui/material";
import Header from '../../components/SuperAdmin/Header';
import { useAuth } from '../../context/auth';


const WorkgroupDetails = () => {
  const {user} = useAuth();
  const userRole = user.user.UserRole;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [members, setMembers] = useState([]);
  const [documents, setDocuments] = useState([]);
  


  const { id: workgroupId, name: workgroupName } = useParams();

  const fetchMembers = useCallback(async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/member/${workgroupId}`);
    const filteredMembers = res.data.members.filter(member => member.UserRole !== 'partner');
    setMembers(filteredMembers);
  }, [workgroupId]);

  const fetchDocuments = useCallback(async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/document/workgroup/${workgroupId}`);
    setDocuments(res.data.documents);
  }, [workgroupId]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchMembers();
      await fetchDocuments();
    };

    fetchData();
  }, [workgroupId, fetchMembers, fetchDocuments]);

 
  
  const documentColumns = [
    {
      field: 'sequence',
      headerName: '#',
      flex: 0.2,
      renderCell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          {row.sequence}
        </div>
      ),
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'image',
      headerName: 'File',
      flex: 2.4,
      renderCell: ({ row }) => (
        <a href={row.image} target="_blank" rel="noopener noreferrer">
          {row.image}
        </a>
      ),
    },
  
  
    {
      field: "UploadDate",
      headerName: "Uploaded Date",
      flex: 1.1,
     
      renderCell: ({ row }) => (
        <div>{new Date(row.UploadDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</div>
      ),
    }
  ];
  

 


  const memberColumns = [
    {
      field: 'sequence',
      headerName: '#',
      flex: 0.2,
      renderCell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          {row.sequence}
        </div>
      ),
    },
    { field: "Name", headerName: "Name", flex: 1 },
    { field: "Number", headerName: "Number", flex: 1 },
    { field: "Email", headerName: "Email", flex: 2, sortable: true },
    { 
      field: "UserRole",  // Added UserRole field
      headerName: "User Role",
      flex: 1,
    },
    { 
      field: "dateAdded", 
      headerName: "Date Added", 
      flex: 1, 
      sortable: true,
      renderCell: ({ row }) => (
        <div>{new Date(row.dateAdded).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</div>
      ),
    },
  
  ];

  return (

     <Box m="20px">

      <Header title={decodeURIComponent(workgroupName)} subtitle="Managing the Workgroup" />

      {userRole === 'superAdmin' && (

        <>
      <Box sx={{display: 'flex', justifyContent:'space-between'}}>
        <Button sx={{ marginRight: '30px', backgroundColor:colors.primary[500] }}  variant="contained" component={Link} to={`/superadmin/addWorkgroupMember/${workgroupId}`}>
          + Add Workgroup Member
        </Button>

        <Button sx ={{backgroundColor:colors.primary[500] }} variant="contained" component={Link} to={`/superadmin/addWorkgroupDocument/${workgroupId}/${workgroupName}`}>
            + Add Workgroup Document
          </Button>

          <Button sx={{  backgroundColor:colors.orangeAccent[500] }} variant="contained"  component={Link} to={`/superadmin/addWorkgroupAdmin/${workgroupId}`}>
            + Add An Admin
          </Button>
        </Box>
        </>
      )}
      <Box
        m="40px 0 0 0"
        height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[500],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={members.map((member, index) => ({ ...member, sequence: index + 1 }))}
          columns={memberColumns}
          getRowId={(row) => row._id}
        />
        <br />

        <DataGrid
          checkboxSelection
          rows={documents.map((document, index) => ({ ...document, sequence: index + 1 }))}
          columns={documentColumns}
          getRowId={(row) => row._id}
        />
      </Box>

     
      </Box>
  );
};

export default WorkgroupDetails;