import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/SuperAdmin/Header';
import axios from 'axios';

const Originators = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [originators, setOriginators] = useState([]);

  useEffect(() => {
    fetchOriginators();
  }, []);

  const fetchOriginators = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/checkOriginators`);
      setOriginators(res.data.originators);
    } catch (error) {
      console.error('Error fetching originators:', error);
    }
  };

 

  const columns = [
    {
        field: 'sequence',
        headerName: '#',
        flex: 0.5,
        renderCell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            {row.sequence}
          </div>
        ),
      },
   
    { field: 'Name', headerName: 'Name', flex: 1, cellClassName: 'name-column--cell',sortable: true  },
    { field: 'Number', headerName: 'Phone Number', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'Email', headerName: 'Email', flex: 1, cellClassName: 'name-column--cell' },
    { field: 'projectCount', headerName: 'Project Count', flex: 1, cellClassName: 'name-column--cell' },
  ];

  return (
    <Box m="20px">
      <Header title="ORIGINATORS" subtitle="Managing Originators" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: 'none',
          },
          "& .MuiDataGrid-cell": {
            borderBottom: 'none',
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[500],
            borderBottom: 'none',
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: 'none',
            backgroundColor: colors.primary[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
      <DataGrid
          checkboxSelection
          rows={originators.map((originator, index) => ({ ...originator, sequence: index + 1 }))}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default Originators;
