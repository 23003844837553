import React, {useState} from 'react';
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate , useParams} from 'react-router-dom';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddAdmin = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [error, setError]= useState();

  // Define validation schema using yup
  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
  });

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      // Send a request to your backend API to add the admin using values.email
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/createWorkgroupAdmin/${id}`, { email: values.email });

      // Check if the request was successful
      if (response.status === 200) {
        // Display success toast
        toast.success('Admin added successfully', {containerId:'addAdminSuccess'});

        // Optionally, you can redirect to another page after successful submission
        navigate('/superadmin/workgroup'); 
      } else {
        // Handle unexpected response status
        setErrors({ email: 'Unexpected error occurred' });
      }
    } catch (error) {
      // Handle specific error scenarios
      if (error.response && error.response.status === 404) {
        setError( 'User not found');
      } else if (error.response && error.response.status === 403) {
        setError('User is not a member of the specified workgroup' );
      } else {
        console.error('Error adding admin:', error);
        setError( 'Unexpected error occurred');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Typography variant="h5" mb={3}>
        Add an Admin to the Workgroup
      </Typography>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box maxWidth={400}>
            <Field
              name="email"
              as={TextField}
              label="Admin Email"
              fullWidth
              margin="normal"
              variant="outlined"
            />

             {/* Display error message directly in the component */}
             {error && (
              <Typography variant="body2" color="error" mt={1}>
                {error}
              </Typography>
            )}

            <Button type="submit" variant="contained" sx={{backgroundColor:'#0087c3'}} mt={2}>
              Add Admin
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default AddAdmin;
