import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/SuperAdmin/Header";
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from "../../theme";

const CreatePartner = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
     
    await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/createPartner`, values);
      
  
    toast.success('Partner created successfully! Awaiting verification', {
      position: 'top-right',
      autoClose: 5000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });


    resetForm();

    navigate('/superadmin/team')
    } catch (error) {
        if (error.response){
          setErrorMessage(`Server responded with a ${error.response.status} message`)
        }else if (error.request){
          setErrorMessage('No response received from the server')
        }else {
          setErrorMessage('Unexpected message error occurred')
        }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE SPONSOR" subtitle="Create a New Sponsor Profile" />

      <Formik
        initialValues={{
          Name: '',
          Email: '',
        }}
        validationSchema={partnerSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>

          

            {errorMessage && (
              <Typography color="secondary" variant="body1" mt={2}>
                {errorMessage}
              </Typography>
            )}


          <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
            {/* Name Input */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.Name}
              name="Name"
              error={touched.Name && !!errors.Name}
              helperText={touched.Name && errors.Name}
              sx={{ gridColumn: "span 4" }}
            />


            {/* Email Input */}
            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.Email}
              name="Email"
              error={touched.Email && !!errors.Email}
              helperText={touched.Email && errors.Email}
              sx={{ gridColumn: "span 4" }}
            />

            
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" sx={{ backgroundColor: colors.primary[500] }}  variant="contained" disabled={isSubmitting}>
                Create New Partner
                </Button>
            </Box>

          
          </form>
        )}
      </Formik>
    </Box>
  );
};



const partnerSchema = yup.object().shape({
  Name: yup.string().required("Name is required"),
  Email: yup.string().email("Invalid email").required("Email is required"),
  
});

export default CreatePartner;
