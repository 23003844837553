import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Button, Typography, Grid, useTheme } from '@mui/material';
import Header from '../../components/SuperAdmin/Header';
import DeleteModal from '../../components/SuperAdmin/DeleteModal';
import UpdateWorkgroupForm from './updateWorkgroupForm';
import { Link } from 'react-router-dom';
import { tokens } from "../../theme";
import { useAuth } from "../../context/auth";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Workgroup = () => {
  const {user} = useAuth();
  const userRole = user.user.UserRole;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [workgroups, setWorkgroups] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workgroupToDelete, setWorkgroupToDelete] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedWorkgroupForUpdate, setSelectedWorkgroupForUpdate] = useState(null);

  useEffect(() => {
    fetchWorkgroups();
  }, []);

  const fetchWorkgroups = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/workgroup`);
      setWorkgroups(res.data.Workgroups);
    } catch (error) {
      console.error('Error fetching workgroups:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const openUpdateModal = (workgroup) => {
    setSelectedWorkgroupForUpdate(workgroup);
    setIsUpdateModalOpen(true);
  };

  const handleUpdate = async (updatedData) => {
    try {
      // Send a put request to update the partner's data
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/${updatedData._id}`, updatedData);
  
      // Fetch updated partners after saving
      fetchWorkgroups();
  
      // Close the modal
      setIsUpdateModalOpen(false);
  
      toast.success('Workgroup Edited Successfully', { containerId: 'container1' });
    } catch (error) {
      console.error('Error editing workgroup:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const deleteWorkgroup = (id) => {
    setWorkgroupToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/workgroup/${workgroupToDelete}`);
      // Update state after successful deletion
      const newWorkgroups = workgroups.filter((workgroup) => workgroup._id !== workgroupToDelete);
      setWorkgroups(newWorkgroups);
      // Close the modal and reset workgroupToDelete
      setIsDeleteModalOpen(false);
      setWorkgroupToDelete(null);
      toast.success('Workgroup Deleted Successfully', { containerId: 'container2' });
    } catch (error) {
      console.error('Error deleting workgroup:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setWorkgroupToDelete(null);
  };

  return (
    <Box m="20px">

    <Box display="flex" justifyContent="space-between">
        <Header title="WORKGROUPS" subtitle="Managing Workgroups" />

        <Button
          sx={{
              marginRight: '30px',
              marginTop: '10px',
              height: '40px', 
              backgroundColor:colors.primary[500],
              }}
          variant="contained"
        
          component={Link}
          to={`/superadmin/createWorkgroup`}
        >
          + Create Workgroup
        </Button>
      </Box>

      

      <Grid container spacing={2} >
        {workgroups.map((workgroup) => (
          <Grid item key={workgroup._id} sx={{ width: '32%'}}>
            <Card>
              
              <CardContent>
                <Typography variant="h5" component="div" sx={{ height: 70, overflow: "hidden" }}>
                  {workgroup.WorkgroupName}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ height: 100, overflow: "hidden", lineHeight: "24px" }}>
                  {workgroup.About}
                </Typography>
              </CardContent>
              <Box display="flex" justifyContent="space-evenly" p={2}>
                {userRole === 'superAdmin' && (

                  <>
                    <Button 
                    variant="outlined"
                    onClick={() => openUpdateModal(workgroup)} // Correct the onClick handler
                    sx={{
                      borderColor: colors.grey[500],
                      color: colors.grey[100],
                      '&:hover':{
                        backgroundColor: colors.primary[500],
                        borderColor: colors.primary[500],
                      }
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    onClick={() => deleteWorkgroup(workgroup._id)}
                    variant="outlined"
                    sx={{
                      borderColor: colors.grey[500],
                      color: colors.grey[100],
                      '&:hover':{
                        backgroundColor: colors.redAccent[500],
                        borderColor: colors.redAccent[500],
                      }
                    }}
                  >
                    Delete
                  </Button>
                  </>
                )}
                
               
                <Button
                  component={Link}
                  to={`/superadmin/workgroupDetails/${workgroup._id}/${encodeURIComponent(workgroup.WorkgroupName)}`}
                  variant="contained"
                  sx={{backgroundColor: colors.primary[500]}}
                >
                  Learn More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Add the UpdateWorkgroupForm */}
      <UpdateWorkgroupForm
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        onUpdate={handleUpdate}
        workgroup={selectedWorkgroupForUpdate}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteConfirmation}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container1" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container2" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container3" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="addAdminSuccess" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container-member" // Specify a unique containerId
      />

      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container-document" // Specify a unique containerId
      />

    </Box>
  );
};

export default Workgroup;
