import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/SuperAdmin/Header";
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateMember = () => {
  const { id: workgroupId } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
     await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/createMember/${workgroupId}`, values);
     
      setTimeout(() => {
        setSuccessMessage('You have successfully added a new Member!');
      }, 1000);
  
      setSuccessMessage('')
      toast.success('Workgroup Member created successfully! Awaiting verification', {
         containerId: 'container-member' 
      });
  
      resetForm();
      navigate('/superadmin/workgroup');
    } catch (error) {
        if (error.response){
            setErrorMessage(`Server responded with a ${error.response.status} message`)
          }else if (error.request){
            setErrorMessage('No response received from the server')
          }else {
            setErrorMessage('Unexpected message error occurred')
          }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE MEMBER" subtitle="Add a New Workgroup Member" />


      {successMessage && (
        <Typography color="secondary" variant="body1" mt={2}>
            {successMessage}
        </Typography>
     )}

        {errorMessage && (
        <Typography color="secondary" variant="body1" mt={2}>
            {errorMessage}
        </Typography>
        )}

      <Formik
        initialValues={{
          Name: '',
          Number: '',
          Email: '',
       
        }}
        validationSchema={memberSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
           

           
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.Name}
              name="Name"
              error={touched.Name && !!errors.Name}
              helperText={touched.Name && errors.Name}
              sx={{ mt: 2 }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.Number}
              name="Number"
              error={touched.Number && !!errors.Number}
              helperText={touched.Number && errors.Number}
              sx={{ mt: 2 }}
            />

            <TextField
              fullWidth
              variant="filled"
              type="email"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.Email}
              name="Email"
              error={touched.Email && !!errors.Email}
              helperText={touched.Email && errors.Email}
              sx={{ mt: 2 }}
            />


            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" sx={{backgroundColor:'#0087c3'}} variant="contained" disabled={isSubmitting}>
                Add New Member
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const memberSchema = yup.object().shape({
  Name: yup.string().required("Name is required"),
  Number: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid - should follow international format")
    .required("Contact number is required"),
  Email: yup.string().email("Invalid email").required("Email is required"),
 
});

export default CreateMember;
