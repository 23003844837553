import React, { useEffect, useState, useContext } from 'react';
import { NavLink} from 'react-router-dom';
import axios from 'axios';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import StageButton from './StageButton';
import { useAuth } from '../../context/auth';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatContext } from '../../context/chatContext';

export default function OriginatorMain() {

  JSON.parse(localStorage.getItem("USER_SGP"));
  const {handleProjectClickAI, projectId, currentChatId} = useContext(ChatContext);

  //const { id } = useParams(); 
  const { user } = useAuth();
  const id = user.user._id;
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  const handleProjectClick = (projectID, projectStage) => {

    handleProjectClickAI(projectID, projectStage)
 
  };
  useEffect(() => {
    // Fetch projects when the component mounts
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
   
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/project/user/${id}`); 
      setProjects(response.data.projects);
    } catch (error) {
     
      console.error('Error fetching projects:', error);
      setError("There was an error fetching your projects. Try again later");
      
    }
  };

  return (
    <>
    <div style ={{ marginTop: '28px'}}>
      <h2 style={{ textAlign: 'left', marginLeft: '13px',color: '#0089c3', fontSize: '19px'}}>My Projects</h2>
        {error && (
          <p className="error-name">{error}</p>
        )}

      <div className="Projects">
        {projects && projects.map((project) => (
          <div
              
              className={`Project ${projectId=== project._id ? 'selected' : ''}`}
              key={project._id}
              onClick={() => handleProjectClick(project._id, project.Stage)}
            >
           
            <img src={project.image} alt={project.ProjectName} />


            <div className='more_container'>
            <div className="name">{project.ProjectName}</div>
          
            <div className="location">
              <LocationOnOutlinedIcon style ={{color: '#333333', fontSize:'15px', marginRight: '5px'}}/>
              {project.Location}
           
            </div>
            <div className="dets" style ={{display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
              <div className="stage" >
                <StageButton project={project}/>
              </div>
              <div className="more">
                <NavLink
                    to={`/originatorProjectDetail/${id}/${project._id}/${encodeURIComponent(project.ProjectName)}/${encodeURIComponent(project.Stage)}`}
                  >
                  <ArrowForwardIosIcon style={{ marginTop: '6px', color: '#f37326' , fontSize:'13px', border:'2px solid #f37326', borderRadius:'50%',padding:'5px'}} />
                </NavLink>
              </div>
            </div>
            </div>
          </div>
        ))}

        {projects.length === 0 && (
          <div className="empty-state">
            <p>You haven't added any projects yet!</p>
            <p>Click the 'Add Project' button to start creating.</p>
            <br />
            <button>
              <ControlPointIcon style={{ fontSize: '20px' }}/>
              <NavLink to="./addProject" className="nav-link" activeClassName="active-nav-link">
                Add Project
              </NavLink>
            </button>

          </div>
      )}
      </div>
      <ToastContainer/>
      <ToastContainer containerId='project-success'/>
      </div>
    </>
  );
}