import './Header.css'
import axios from 'axios';
import { NavLink, useNavigate} from 'react-router-dom';
import { useAuth } from '../../context/auth';

export default function Header({userRole}){
    JSON.parse(localStorage.getItem("USER_SGP"));
    const navigate = useNavigate();
    const auth = useAuth();

    const handleLogout = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`);
        // Call the logout function from useAuth
        auth.logout();
        navigate('/');
        
        }
    return (
        <div className="Header">
        <div className="left-section">
            <img src="/acmi-logo-color.png" alt="Logo" className="logo" style ={{marginBottom:'0px', maxHeight: '40px'}}/>
        </div>
        
            <div className="centered-section">

           
            <NavLink
                className="header-nav-link"
                activeClassName="active-nav-link"
                style={{ color: 'rgb(85, 84, 84)' }} 
                to={
                    userRole === 'member' ? '/member' : 
                    userRole === 'admin' ? '/admin':
                    userRole === 'partner' ? '/partner' :
                    userRole === 'originator' ? '/developer':'/'}
            >
                Home
            </NavLink>


                {userRole==='originator' && <NavLink to ='/developer' className="header-nav-link" activeClassName="active-nav-link">My Projects</NavLink> }
                {userRole==='admin' && <NavLink to ='/admin' className="header-nav-link" activeClassName="active-nav-link">Workgroups</NavLink> }
                {userRole==='member' && <NavLink to ='/member' className="header-nav-link" activeClassName="active-nav-link">Workgroups</NavLink> }
                {userRole==='partner' && <NavLink to ='/partner' className="header-nav-link" activeClassName="active-nav-link"></NavLink> }


            </div>
            <div className="right-section">
            <button onClick={handleLogout}>
                    <NavLink className="header-nav-link" style ={{color:'#fdfff5', fontSize:'13px'}}>Sign Out</NavLink>
            </button>
            </div>
        </div>

    )
}