import { useState, useEffect, createContext} from 'react';
import axios from 'axios'; 
import { useAuth } from './auth';
import { v4 as uuidv4 } from 'uuid';

export const ChatContext = createContext()

export const ChatContextProvider = ({children}) =>{

    const { user } = useAuth();
    const [chats, setChats] = useState([]);
    const [prompts, setPrompts] = useState([])
    const [projects, setProjects] = useState([])
    const [stage, setStage] = useState(null)
    const [prompt, setPrompt] = useState(null);
    const [error, setErrors] = useState(null)
    const [projectId, setProjectId] = useState (null);
    const [currentChatId, setCurrentChatId] = useState(null);
    const [loading, setLoading] = useState(false);

    let promptChatId = currentChatId;
    let promptProjectId = projectId; 
    

    useEffect(() => {
      
        if (user && user.user && user.user._id) {
          const userId = user.user._id;
          fetchChatsInitial(userId);
          fetchProjects(userId);
        } else {
          setChats([]);
          setCurrentChatId(null);
          setPrompts([]);
          setProjectId(null);
          setStage(null);
          setProjects([]);
          setErrors('User is not logged in');
          
        }
      }, [user]);

   
    

    const fetchPrompts = async (currentChatId) => {
        let apiUrl; 
        if (user && user.user){
            if (user.user.UserRole === 'originator'){
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chat/getPrompts/${promptChatId}`;
            }else {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chatWorkgroup/getPrompts/${promptChatId }`; 
            }
        }
        
        try {
            const response = await axios.get(`${apiUrl}`);
            
            const promptsData = response.data;
            
            setPrompts(promptsData);

            console.log('this was successfull')
        } catch (error) {
            setErrors(error.response.data);
            //console.log('This was not sucessful')
            //console.log('The prompt chat id is', promptChatId)
            //console.log('The current chat id is', currentChatId)
        }
        
    };

    useEffect(() => {
        // Call fetchPrompts if currentChatId is not null
        if (currentChatId !== null && currentChatId !== '' && promptChatId !== '') {
            fetchPrompts(promptChatId );
        }
    }, [currentChatId]);
     
    

      const fetchChatsInitial = async(userId)=>{

        let apiUrl; 
        if (user && user.user){
            if (user.user.UserRole === 'originator'){
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chat/getChats/${userId}`;
            }else {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chatWorkgroup/getChats/${userId}`; 
            }
        }
        
        try{
            const response = await axios.get(`${apiUrl}`)
            const chatsData = response.data;
            setChats(chatsData);
            promptChatId = '';
            
            if (chatsData.length > 0) {
                const mostRecentChat = chatsData[chatsData.length - 1];
                setCurrentChatId(mostRecentChat._id);  
                promptChatId = mostRecentChat._id;
                setStage(mostRecentChat.projectStage);
            }
            
          }catch(error){
            setErrors(error.response.data);
          }
      }


      const fetchChats = async(userId)=>{

        let apiUrl; 
        if (user && user.user){
            if (user.user.UserRole === 'originator'){
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chat/getChats/${userId}`;
            }else {
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chatWorkgroup/getChats/${userId}`; 
            }
        }
        
        try{
            const response = await axios.get(`${apiUrl}`)
            const chatsData = response.data;
            setChats(chatsData);
            
          }catch(error){
            setErrors(error.response.data);
          }
      }
     const deleteChat = async (chatId) =>{
        
        let apiUrl; 
        if (user && user.user && user.user._id){
            const userId = user.user._id;
            const userRole = user.user.UserRole; 
        try{

            if (prompts && prompts.length > 0){

                try{
                    await axios.delete('https://staging-bayesnet.bayesconsultants.com/api/acmi_knowledgeShare/delete_chat', {
                        chat_id: currentChatId
                    });
                   
                }catch(error){
                    setErrors(error.response.data);
                }
                
            }

            if (userRole === 'originator'){
                apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/chat/${currentChatId}`;
            }else {
               apiUrl =  `${process.env.REACT_APP_BASE_URL}/v1/chatWorkgroup/${currentChatId}`;
            }
            await axios.delete(`${apiUrl}`)
            await fetchChatsInitial(userId); 
            
            setPrompts([]);
            setProjectId(null);
            //window.location.reload();
          }catch(error){
            setErrors(error.response.data);
        }
    }
     }

     //Create chat 
     const createChat = async () => {
        if (user && user.user && user.user._id) {
            const userId = user.user._id;
            const userRole = user.user.UserRole; 
            const endpoint = userRole === 'originator' ? `${process.env.REACT_APP_BASE_URL}/v1/chat` : `${process.env.REACT_APP_BASE_URL}/v1/chatWorkgroup`;
            
            try {
                // Create a new chat
                const response = await axios.post(endpoint, {
                    projectId:promptProjectId,
                    userId
                });
    
                // Get the id of the newly created chat
                const newChatId = response.data._id;
    
                // Fetch chats again to update the list
                await fetchChats(userId);
                
                // Set the currentChatId as the id of the newly created chat
                setCurrentChatId(newChatId);
                promptChatId = newChatId; 
               
            } catch (error) {
                setErrors(error.response.data);
            }
        } else {
            setErrors('User is not logged in');
        }
    };
    
    

    //const fetch user projects
    const fetchProjects = async (userId) => {
        try {
            let apiUrl;
            if (user && user.user){
                if (user.user.UserRole === 'originator') {
                    apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/project/user/${userId}`;
                } else {
                    apiUrl = `${process.env.REACT_APP_BASE_URL}/v1/workgroup`;
                }
            }
    
            const response = await axios.get(apiUrl);
            setProjects(response.data.projects);
        } catch (error) {
            setErrors(error.response.data);
        }
    };
    

    const addPrompt = async () => {
        try {
            let apiUrl;
           
            if (stage === "General") {
                apiUrl = 'https://staging-bayesnet.bayesconsultants.com/api/acmi_general';
            } else if (stage === 'All'){

                if (user && user.user && user.user.userRole === 'originator'){
                    apiUrl = 'https://staging-bayesnet.bayesconsultants.com/api/acmi_knowledgeShare_all';
                }else {
                    apiUrl = 'https://staging-bayesnet.bayesconsultants.com/api/acmi_work_groups_all';
                }
                
            }else {
                if (user && user.user){
                   
                    if (user.user.UserRole === 'originator'){
                        apiUrl = 'https://staging-bayesnet.bayesconsultants.com/api/acmi_knowledgeShare';
                    }else {
                        apiUrl = 'https://staging-bayesnet.bayesconsultants.com/api/acmi_work_groups';
                    }
                    
                }
                
            }

            let project_details;

            //Check if the projectId is not null 
            if(projectId != null){
                try{
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/project/${projectId}`);
                    const { type, description } = response.data.project_details;

                    // Construct project_details string with identifier
                    project_details = JSON.stringify({ type, description });
                    project_details = `"project_details": ${project_details}`;
                }catch(error){
                    setErrors(error)
                }
            }

    
            const requestData = {
                id: uuidv4(),
                pre_prompt: project_details || '',
                prompt: prompt,
                chat_id: promptChatId,
                context: stage,
            };

           
    
            setLoading(true);
            const response = await axios.post(apiUrl, requestData);
    
            const promptData = {
                chatID: promptChatId,
                context: stage,
                promptText: prompt,
                response: response.data.response,
                citations: response.data.citation,
            };

            let promptUrl;

            if (user && user.user){
                if (user.user.UserRole === 'originator'){
                    promptUrl = `${process.env.REACT_APP_BASE_URL}/v1/prompt/`;
                }else {
                    promptUrl = `${process.env.REACT_APP_BASE_URL}/v1/promptWorkgroup/`;
                }
            }

            
    
            await axios.post(`${promptUrl}`, promptData);
            fetchChats(user.user._id); 
            fetchPrompts(promptChatId);
        } catch (error) {
           // setErrors(error.response.data);
           setErrors(error)
           console.log(error)
        } finally {
            setPrompt('');
            setLoading(false);
        }
    };


    const resetAIChat = () =>{
        setCurrentChatId(null);
        setPrompts([]);
        setProjectId(null);
        
    }
   
   useEffect(()=>{
       
   },[projectId])


    const handleProjectClickAI = (clickedProjectId, projectStage) => {
        
        setProjectId(clickedProjectId);


        if (projectStage === 'Sale&Retirement'){
            setStage ('Sale-and-retirement');
          }else {
            setStage(projectStage);
          }
        
          
        const existingChat = chats.find(chat => chat.projectId === clickedProjectId && chat.projectStage === projectStage);
    
        if (existingChat) {
            // If there's an existing chat, set it as the current chat
            setCurrentChatId(existingChat._id);
        } else {
            promptProjectId = clickedProjectId;
            // If no existing chat, create a new chat
            createChat();
        }
    }
    
    
    return(
        <ChatContext.Provider value = {{ handleProjectClickAI, resetAIChat, error, chats, deleteChat, createChat, setProjectId, projectId, projects, currentChatId, setCurrentChatId, prompts, setPrompts, stage, setStage, prompt, setPrompt, addPrompt, loading}}>
            {children}
        </ChatContext.Provider>
    )
}