import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/SuperAdmin/Header";
import { useEffect, useState } from "react";
import axios from "axios";


const Project = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [projects, setProjects] = useState([]);
  

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/project`); 
    setProjects(res.data.projects);
  };

 


  const columns = [

    {
      field: 'sequence',
      headerName: '#',
      flex: 0.5,
      renderCell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          {row.sequence}
        </div>
      ),
    },
    { field: "ProjectName", headerName: "Project Name", flex: 1, cellClassName: "name-column--cell", sortable: true  },
    { field: "originatorName", headerName: "Originator", flex: 1, cellClassName: "name-column--cell" , sortable: true },
    { field: "Location", headerName: "Location", flex: 1, cellClassName: "name-column--cell" , sortable: true },
    { field: "Stage", headerName: "Stage", flex: 1, cellClassName: "name-column--cell", sortable: true  },
  ];
  

  return (
    <Box m="20px">
      <Header title="PROJECTS" subtitle="Managing Projects" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[500],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
         
          rows={projects.map((project, index) => ({ ...project, sequence: index + 1 }))}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>

     
    </Box>
  );
};

export default Project;
