// ContextProvider.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
    const [disallowApproval, setDisallowApproval] = useState(false);

    useEffect(() => {
        // Fetch global setting and update state
        const fetchGlobalSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/globalSettings`);
                setDisallowApproval(response.data.disallowApproval);
            } catch (error) {
                console.error('Error fetching global setting:', error);
            }
        };

        fetchGlobalSetting();
    }, []); // Run once on component mount

    return (
        <AdminContext.Provider value={{ disallowApproval, setDisallowApproval }}>
            {children}
        </AdminContext.Provider>
    );
};

export default AdminContextProvider;
