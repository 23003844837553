

const StageButton = ({ project }) => {
  // Mapping of stages to background colors
  const stageColors = {
    'Sale&Retirement': '#00CED1',
    'Issuance': '#005253',
    'Verification': 'orange',
    'Monitoring': '#F39C12',
    'Implementation': '#1ABC9C',
    'Registration': '#2980B9',
    'Validation': '#8E44AD',
    'Design': '#E74C3C',
  };

  // Get the background color based on the project's stage
  const backgroundColor = stageColors[project.Stage] || 'gray';

  return (
    <div className="stage">

        {project.Stage !== 'Sale&Retirement' && (
          <button style={{ backgroundColor }}>
          {project.Stage}
          </button>
          )}

        
        {project.Stage === 'Sale&Retirement' && (
          <button style={{ backgroundColor }}>
            Sales & Retirement
          </button>
          )}
        
    </div>
  );
};

export default StageButton;
