import React, { useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ChatContext } from '../../context/chatContext';


function AIDropdown() {

  const { createChat } = useContext(ChatContext);

  const handleCreateNewChat = async() =>{
    await createChat(); 
  }
  return (
   
    <div className="title">
          <h2>My Chats</h2>
          <button onClick={handleCreateNewChat}>
            <FontAwesomeIcon icon={faPlus} size="s" style={{ color: 'white', marginRight:'10px' }} />
            <p style ={{fontWeight:'600'}}>New Chat</p>
          </button>
      </div>  
  );
}

export default AIDropdown;
