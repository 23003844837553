import React, { useContext} from 'react';
import { WorkgroupContext } from '../../context/workgroupContext';
import { NavLink } from 'react-router-dom';


const Trial = () => {

    const { workgroups, error, defaultWorkgroup, setDefaultWorkgroupID} = useContext(WorkgroupContext);
    return(
        <div>

            <div>
                <h3>Default Workgroup</h3>

                {defaultWorkgroup && (
                    <div>
                        <p>The Name: {defaultWorkgroup.WorkgroupName}</p>
                        <p>The About: {defaultWorkgroup.About}</p>
                        <p>Member Count: {defaultWorkgroup.memberCount}</p>
                        <p>Document Count: {defaultWorkgroup.documentCount} </p>
                        {defaultWorkgroup.documentDetails && defaultWorkgroup.documentDetails.map((document, index) => (
                <div key={index}>
                    <p>Document Name: {document.filename}</p>
                   
                    <NavLink to={document.image} target="_blank">
                        <button>       
                            Download
                        </button>
                    </NavLink>
                </div>
            ))}
                    </div>
                )}  
            </div>

            <div>
                <h3>Documents</h3>

              
            </div>
            <div>
                <h3>All Workgroups</h3>

                <p style ={{color: 'red'}}>{error}</p>

                <ul>
                    {workgroups.map(workgroup => (
                        <div>
                            <li key={workgroup._id} onClick ={() => setDefaultWorkgroupID(workgroup._id)}>{workgroup.WorkgroupName}</li>
                         
                        </div>
                    ))}
                </ul>

            </div>

            
        </div>
    )
}


export default Trial; 