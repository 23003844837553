import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/SuperAdmin/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import DeleteModal from "../../components/SuperAdmin/DeleteModal";
import UpdatePartnerForm from "./updatePartnerForm";
import { useAuth } from "../../context/auth";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Team = () => {
  const {user} = useAuth();
  const userRole = user.user.UserRole;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [partners, setPartners] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [partnerToDelete, setPartnerToDelete] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedPartnerForUpdate, setSelectedPartnerForUpdate] = useState(null);


  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/checkPartners`);
    setPartners(res.data.partners);
  };

  const deletePartner = (_id) => {
    setPartnerToDelete(_id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/v1/auth/delete/${partnerToDelete}`);

      // Update state after successful deletion
      const newPartners = partners.filter((partner) => partner._id !== partnerToDelete);
      setPartners(newPartners);

      // Close the modal and reset partnerToDelete
      setIsDeleteModalOpen(false);
      setPartnerToDelete(null);

      toast.success('Partner Deleted Successfully', { containerId: 'container2' });
    } catch (error) {
      console.error('Error deleting partner:', error);
      // Handle error (show error message, log, etc.)
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setPartnerToDelete(null);
  };

  const openUpdateModal = (partner) => {
    setSelectedPartnerForUpdate(partner);
    setIsUpdateModalOpen(true);
  };

  const handleUpdate = async (updatedData) => {
    try {
      // Send a put request to update the partner's data
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/auth/update/${updatedData._id}`, updatedData);
  
      // Fetch updated partners after saving
      fetchPartners();
  
      // Close the modal
      setIsUpdateModalOpen(false);
  
      toast.success('Partner Updated Successfully', { containerId: 'container1' });
    } catch (error) {
      console.error('Error updating partner:', error);
      // Handle error (show error message, log, etc.)
    }
  };
  
  const actionsColumn = userRole === 'superAdmin' ? {
    field: 'Actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: ({ row }) => (
      <Box style={{ display: 'flex', gap: '10px' }}> 
        <Button
          onClick={() => openUpdateModal(row)}
          variant="contained"
          sx={{ backgroundColor: colors.primary[500] }} 
        >
          Edit
        </Button>
        <Button
          onClick={() => deletePartner(row._id)}
          variant="contained"
          sx={{ backgroundColor: colors.redAccent[500] }} 
        >
          Delete
        </Button>
      </Box>
    ),
  } : {};
  const columns = [
    {
      field: 'sequence',
      headerName: '#',
      flex: 0.5,
      cellClassName: "name-column--cell" ,
      renderCell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          {row.sequence}
        </div>
      ),
    },
    { field: "Name", headerName: "Name", flex: 1, cellClassName: "name-column--cell" },
    { field: "Email", headerName: "Email", flex: 1,sortable: true, cellClassName: "name-column--cell"  },
    actionsColumn,
  ];

  return (
    <Box m="20px">
      <Header title="SPONSORS" subtitle="Managing ACMI Sponsors" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.primary[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[500],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[500],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.primary[500]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={partners.map((partner, index) => ({ ...partner, sequence: index + 1 }))}
          columns={columns}
          getRowId={(row) => row._id}
        />

       
      </Box>
      <ToastContainer/>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteConfirmation}
      />

      <UpdatePartnerForm
    isOpen={isUpdateModalOpen}
    onClose={() => setIsUpdateModalOpen(false)}
    onUpdate={handleUpdate} // Add the update logic function here
    partner={selectedPartnerForUpdate}
    />

    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container1" // Specify a unique containerId
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="container2" // Specify a unique containerId
      />

    </Box>
  );
};

export default Team;
