import React, { useState } from 'react';
import './Originator.css';
import Header from './Header';
import OriginatorTop from './OriginatorTop';
import Quiz from './Quiz';
import AI from '../AIChat/AI';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ImageIcon from '@mui/icons-material/Image';


import TagIcon from '@mui/icons-material/Tag';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

export default function AddProject() {
  JSON.parse(localStorage.getItem("USER_SGP"));
  const userRole = 'originator';

  const { user } = useAuth();
  const id = user.user._id;
  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [ProjectName, setProjectName] = useState('');
  const [Location, setLocation] = useState('');
  const [ProjectType, setProjectType] = useState('');
  const [ProjectDescription, setProjectDescription] = useState('');
  const [Stage, setStage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [countrySuggestions, setCountrySuggestions] = useState([]);

  const countries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cabo Verde',
    'Cameroon', 'Central African Republic', 'Chad', 'Comoros', 'Congo', 'Democratic Republic of the Congo', 'Djibouti', 'Egypt',
    'Equatorial Guinea', 'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon', 'Gambia', 'Ghana',
    'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya',
    'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco', 'Mozambique',
    'Namibia', 'Niger', 'Nigeria', 'Rwanda', 'São Tomé and Príncipe', 'Senegal', 'Seychelles',
    'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Tanzania', 'Togo',
    'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
];


  const projectTypes = ['Forestry', 'Land use', 'Agriculture (arable)', 'Agriculture (pastoral)', 
   'Renewable Energy', 'Energy efficiency', 'Industry gases',
   'Household devices', 'Waste Management', 'Transportation', 'Engineered Carbon Dioxide Removal (CDR)',
   'Blue carbon', 'Others'];

  const handleStageSelection = (selectedStage) => {
    setStage(selectedStage);
  };

  const handleCountryChange = (value) => {
    const filteredCountries = countries.filter(country =>
      country.toLowerCase().startsWith(value.toLowerCase())
    );
    setCountrySuggestions(filteredCountries);
    setLocation(value);
  };

  const handleCountrySelect = (selectedCountry) => {
    setLocation(selectedCountry);
    setCountrySuggestions([]);
  };

  const handleProjectTypeChange = (value) => {
    setProjectType(value);
  };

  const validateForm = async () => {
    const schema = Yup.object().shape({
      ProjectName: Yup.string().required('Project Name is required'),
      Location: Yup.string().required('Location is required'),
      image: Yup.mixed().required('Image is required'),
      Stage: Yup.string().required('Stage is required'),
      ProjectType: Yup.string().required('Project Type is required'),
      ProjectDescription: Yup.string().min(100, 'Project Description must be at least 100 characters'),
    });

    try {
      await schema.validate({ ProjectName, Location, image: file, Stage, ProjectType, ProjectDescription }, { abortEarly: false });
      return true;
    } catch (validationError) {
      const errors = {};
      validationError.inner.forEach(error => {
        errors[error.path] = error.message;
      });
      setErrors(errors);
      return false;
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    if (!await validateForm()) {
      return; // Do not proceed with submission if there are validation errors
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('ProjectName', ProjectName);
    formData.append('Location', Location);
    formData.append('Stage', Stage);
    formData.append('image', file);
    formData.append('OriginatorID', id);
    formData.append('ProjectType', ProjectType);
    formData.append('ProjectDescription', ProjectDescription);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/project/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success('Project Added Successfully', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        containerId:'project-success',
      });

      navigate('/developer');
    } catch (error) {
      console.error('Error submitting project:', error);
    } finally {
      setLoading(false); // Set loading back to false once submission is complete
    }
  };

  return (
    <div className="Originator">
      <Header userRole={userRole} />
      <OriginatorTop userRole={userRole} />

      <div className="Main">
        <div className="Main-Left">
          <div style={{ marginTop: '40px' }}>
            <h3 style={{ fontSize: '15px', color:'#0087c3'}}>Add New Project</h3>

            <form onSubmit={submit} style={{ backgroundColor: 'white' }}>
              <div className="form-group" style={{marginBottom:'15px'}}>
                <input
                  className={`did-floating-input ${ProjectName ? 'focused' : ''}`}
                  style={{ width: '95%'}}
                  type="text"
                  placeholder="Name"
                  name="ProjectName"
                  autoComplete="off"
                  value={ProjectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
                <label className="did-floating-label">
                  <span><TextFieldsIcon style={{ fontSize:'18px', marginRight:'5px' }}/></span> Project Name
                </label>
                {errors.ProjectName && (
                  <div className="formik-error">{errors.ProjectName}</div>
                )}
              </div>
              

              <div className="form-group">
                <input
                  className={`did-floating-input ${Location ? 'focused' : ''}`}
                  style={{ width: '95%'}}
                  type="text"
                  placeholder="Location"
                  autoComplete="off"
                  name="Location"
                  value={Location}
                  onChange={(e) => handleCountryChange(e.target.value)}
                />
                {countrySuggestions.length > 0 && (
                  <div className="autocomplete-items">
                    {countrySuggestions.map((country, index) => (
                      <div
                        key={index}
                        onClick={() => handleCountrySelect(country)}
                        className="autocomplete-item"
                      >
                        <p style ={{fontSize:'12px', color:'#0087c3', cursor: 'pointer' }}>{country}</p>
                      </div>
                    ))}
                  </div>
                )}
                <label className="did-floating-label">
                  <span><LocationOnOutlinedIcon style={{ fontSize:'18px', marginRight:'5px' }}/></span> Country
                </label>
                {errors.Location && (
                  <div className="formik-error">{errors.Location}</div>
                )}
              </div>

              <div className="select-input" style={{ borderColor: ProjectType ? '#ccc' : '', marginBottom:'15px' }}>
                <div style ={{color: '#36454fcd', fontSize:'12px', fontWeight:'bold', paddingLeft:'3px'}}>
                  <span><TagIcon style={{ fontSize:'14px', marginRight:'5px' }}/></span> Project Type
                </div>

                <br />

                <div  style ={{display:'flex', flexWrap:'wrap', paddingLeft:'3px'}} >
                  {projectTypes.map((type, index) => (
                    <label key={index} style={{ marginRight: '20px', width:'45%', fontSize:'12px', fontWeight:'500', paddingBlock:'5px' }}>
                      <input
                     
                        type="radio"
                        name="projectType"
                        value={type}
                        checked={ProjectType === type}
                        onChange={() => handleProjectTypeChange(type)}
                      /> {type}
                
                    </label>
                  ))}
                </div>

               
                {errors.ProjectType && (
                  <div className="formik-error">{errors.ProjectType}</div>
                )}
              </div>

              <div className="form-group" style={{marginBottom:'15px'}}>
                <textarea
                  className={`did-floating-input ${ProjectDescription ? 'focused' : ''}`}
                  style={{ width: '95%', minHeight: '200px', height:'auto', padding: '10px', resize: 'none' }}
                  placeholder="Project Description"
                  name="ProjectDescription"
                  value={ProjectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                  title="A minimum of 100 words and maximum of 300 words" 
                />
                <label className="did-floating-label">
                  <span><DescriptionOutlinedIcon style={{ fontSize:'18px', marginRight:'5px' }}/></span> Short Description about the Project
                </label>
                {errors.ProjectDescription && (
                  <div className="formik-error">{errors.ProjectDescription}</div>
                )}
              </div>

              <div className="did-floating-input" style ={{height:'auto',width: '95%', border: file ? '2px solid #ccc' : '2px solid #FF7A82',paddingBlock:'16px' }}>

                <label htmlFor="fileInput" style={{fontWeight:'bold', fontSize:'12px', color:'#36454fcd'}}>
                <span><ImageIcon /></span> {file ? file.name : "Choose a Project Image"}
                </label>
                
                <input
                  id="fileInput"
                  className=""
                  style={{
                    display: 'none' // Hide the default file input button
                  }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => setFile(e.target.files[0])}
                />
               
              </div>
              {errors.image && (
                  <div className="formik-error">{errors.image}</div>
                )}


              <Quiz onStageSelection={handleStageSelection} />

              <div style={{ width: '95%' }}>
                <button className="btn-success" type="submit" disabled={loading}>
                  {loading ? <div className="loader"></div> : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="Main-Right">
          <AI userRole={userRole} />
        </div>
      </div>
    </div>
  );
}
