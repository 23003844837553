import { Modal, Button, Typography, Stack, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';

const AddWorkgroupDocument = ({ isOpen, onClose }) => {
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [error, setError] = useState('');

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/upload`, formData);

      onClose(); // Close the modal after successful upload, you can customize this behavior
    } catch (err) {
      console.error(err);
      setError('Error uploading the document')
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setSelectedFileName(selectedFile ? selectedFile.name : '');

  };

  const handleUploadClick = () => {
    if (file) {
      upload();
    } else {
        setError ('Error Uploading the Document')
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >

      {error &&  (
        <Typography variant="body2" style ={{color:'red'}}>{error}</Typography>
      )}
        <Typography variant="h4">Add Stakeholders Document</Typography>
      
        <Typography style={{ color: 'red', marginBlock:'10px' }} variant="body2" gutterBottom>
            Please ensure that the JSON is named as extracted_data_final.json
        </Typography>



        <OutlinedInput
          style={{ display: 'none' }}
          type="file"
          id="file"
          name=""
          onChange={handleFileChange}
        />
        <label className="file" htmlFor="file">
          <Button variant="outlined" component="span">
            Upload Document
          </Button>
        </label>

         {/* Display the selected file name */}
      {selectedFileName && (
        <Typography variant="body2">{selectedFileName}</Typography>
      )}

        <Button variant="contained" color="primary" onClick={handleUploadClick} sx={{ mt: 2 }}>
          Upload Stakeholder Document
        </Button>
      </Stack>
    </Modal>
  );
};

export default AddWorkgroupDocument;
