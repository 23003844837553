import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './ModalComponent.css';
import { WorkgroupContext } from '../../../context/workgroupContext';
import axios from 'axios';

const ModalComponentAdminInfo = ({ isOpen, onRequestClose,}) => {

  const { defaultWorkgroupID } = useContext(WorkgroupContext);
  const [adminData, setAdminData] = useState(null); // State to store admin data

  // Fetch admin information when defaultWorkgroupID changes
  useEffect(() => {
    if (defaultWorkgroupID) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/v1/auth/workgroup/${defaultWorkgroupID}/admin-info`)
        .then(response => {
          setAdminData(response.data); // Set admin data in state
        })
        .catch(error => {
          console.error('Error fetching admin info:', error);
          setAdminData({ error: true }); // Set error flag in state
        });
    }
  }, [defaultWorkgroupID]);


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Admin Information Modal"
      className="custom-modal-content"
      overlayClassName="custom-modal-overlay"
    >
      <div>
        <h2 className="modal-title" style ={{color: '#0089c3'}}>WorkGroup Admin Contact Information</h2>
        
        
        {adminData && !adminData.error ? (
          <>
            <p className="modal-text-header">Please, feel free to reach out to the Admin on any <br />matters concerning the Workgroup</p>
           
            <p className="modal-text-header">
              <span style ={{marginRight: '7px'}}><FontAwesomeIcon icon={faUser} /></span> {adminData.Name}
            </p>
            <p className="modal-text-header">
              <span style ={{marginRight: '5px'}}><FontAwesomeIcon icon={faEnvelope} /></span> {adminData.Email}
            </p>
            <p className="modal-text-header">
              <span style ={{marginRight: '5px'}}><FontAwesomeIcon icon={faPhone} /> </span> {adminData.Number}
            </p>
            
          </>
        ) : (
          <p className="modal-text">Admin not found for the specified workgroup.</p>
        )}

        <button className="modal-button" onClick={onRequestClose}>Close</button>
      </div>
    </Modal>
  );
};

export default ModalComponentAdminInfo;
